import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { useStore } from '../../stores/store';
import { Link } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import NewUserModel, { NewUserModelSchema } from '../../models/NewUser';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import LoadingButton from '@mui/lab/LoadingButton';
import { Select } from '@mui/material';
import { observer } from 'mobx-react-lite';
import CircularProgress from '@mui/material/CircularProgress';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default observer(function CreateNewUser() {
    const { userStore } = useStore();
    const { loading, editUser, getCompanyRoles, loadCompanyRoles, checkUniqueUsername, IsUsernameAvailable } = userStore;
    let [checkUserStatus, setCheckUserStatus] = React.useState(0); //0 mean never checked, 1 means check and not unique, 2 means checks and we are unique
    const cono = window.localStorage.getItem('cono');
    //  useEffect(() => {
    //     if(editUser){
    //         formik.values.email = editUser!.email;
    //         console.log('check e ' + formik.values.email);
    //         console.log('check data ' + editUser!.email);
    //     }
    //  }, [])
    useEffect(() => {
        loadCompanyRoles();
    }, []);

    const formik = useFormik({
        initialValues: new NewUserModel(),
        onSubmit: values => {
            formik.setFieldValue('cono', cono);
            //alert(JSON.stringify(values, null, 2));
            userStore.createNewUser(values).then(() => {
                console.log('done trying to create new user');
                handleReset();
            })
        },
        validationSchema: NewUserModelSchema,
        validateOnBlur: true,

    });

    function IsUsernameUnique() {
        if (checkUserStatus == 0) {
            return (
                <></>
            )
        }
        if (checkUserStatus == 1) {
            return (
                <ClearRoundedIcon color="error" />
            )
        }
        if (checkUserStatus == 2) {
            return (
                <DoneRoundedIcon color="success" />
            )
        }
        return (<></>);

    };

    // const handleChangehandleChangeRole = (event: SelectChangeEvent<typeof formik.values.roleid>) => {
    //     const {
    //       target: { value },
    //     } = event;
    //     setRoles(
    //       // On autofill we get a stringified value.
    //       typeof value === 'string' ? value.split(',') : value,
    //     );
    //   };
    const handleInput = async () => {
        if (formik.values.email) {
            await IsUsernameAvailable(formik.values.email).then((response) => {
                console.log('ok ' + response);
                if (response === true) {
                    setCheckUserStatus(2);
                } else {
                    console.log('set 1');
                    setCheckUserStatus(1);
                }
            });
        }
    };
    const handleChange = () => {
        console.log('change');
    };
    const handleChange2 = (event: { target: { value: any; }; }) => {
        const {
            target: { value },
        } = event;
    };

    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: window.localStorage.getItem('cono') || '1', firstname: '', lastname: '', credentials: '', email: '', optinemail: false, optintext: false, phone: '', 
                      roleid: 0, hiredate: '', roles: [], employeeid: '', username: '', isagency:false, secondaryroles:[] },
        });
    };
    // console.log('Form Values', formik.values);

    return (
        <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/users"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            {!editUser
                                ? <span>Create New User</span>
                                : <span>Edit user</span>
                            }
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>First Name</InputLabel>
                        <TextField
                            error={!!formik.errors.firstname}
                            name="firstname"
                            size='small'
                            value={formik.values.firstname}
                            onChange={formik.handleChange} />
                        {formik.errors.firstname &&
                            <Typography style={{ marginBottom: 10 }} color='red'>First Name Is Required</Typography>
                        }
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Last Name</InputLabel>
                        <TextField
                            error={!!formik.errors.lastname}
                            name="lastname"
                            size='small'
                            value={formik.values.lastname}
                            onChange={formik.handleChange} />
                        {formik.errors.lastname &&
                            <Typography style={{ marginBottom: 10 }} color='red'>Last Name Is Required</Typography>
                        }
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Employee ID</InputLabel>
                        <TextField
                            error={!!formik.errors.employeeid}
                            name="employeeid"
                            size='small'
                            value={formik.values.employeeid}
                            onChange={formik.handleChange} />
                        <FormGroup>
                            <FormControlLabel onChange={formik.handleChange} name="isagency" control={<Checkbox checked={formik.values.isagency} />} label="Agency User" />
                        </FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Credentials</InputLabel>
                        <TextField
                            error={!!formik.errors.credentials}
                            name="credentials"
                            size='small'
                            value={formik.values.credentials}
                            onChange={formik.handleChange} />
                        {/* <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Hire Date</InputLabel>
                        <input
                            type="date"
                            name="hiredate"
                            value={formik.values.hiredate}
                            onChange={formik.handleChange}
                        /> */}
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Email Address</InputLabel>
                        <TextField
                            error={!!formik.errors.email}
                            name="email"
                            size='small'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={handleInput} /> {checkUniqueUsername ? <><CircularProgress /></> : <><IsUsernameUnique /></>}
                        {formik.errors.email &&
                            <Typography style={{ marginBottom: 10 }} color='red'>Email Is Required</Typography>
                        }
                        <FormGroup>
                            <FormControlLabel onChange={formik.handleChange} name="optinemail" control={<Checkbox checked={formik.values.optinemail} />} label="Allow Email Alerts" />
                        </FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Phone Number</InputLabel>
                        <TextField
                            error={!!formik.errors.phone}
                            name="phone"
                            size='small'
                            value={formik.values.phone}
                            onChange={formik.handleChange} />
                        <FormGroup>
                            <FormControlLabel onChange={formik.handleChange} name="optintext" control={<Checkbox checked={formik.values.optintext} />} label="Allow Phone Alerts" />
                        </FormGroup>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ textAlign: 'left' }}>Role</InputLabel>
                        <Select
                            name="roleid"
                            value={formik.values.roleid}
                            onChange={formik.handleChange}
                            displayEmpty
                            size='small'
                            style={{ display: "block" }}
                        // input={<OutlinedInput />}
                        // multiple
                        // renderValue={(selected) => {
                        //     if (selected.length === 0) {
                        //         return <em>Placeholder</em>;
                        //     }

                        //     return selected.join(', ');
                        // }}
                        // MenuProps={MenuProps}
                        // inputProps={{ 'aria-label': 'Without label' }}
                        >
                            {getCompanyRoles.map((role) => (
                                <MenuItem key={role.roleid} value={role.roleid}>{role.role}</MenuItem>
                            ))}
                        </Select>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2, borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            size='medium'
                            fullWidth
                            onClick={formik.submitForm}
                            loading={loading}>
                            Create User
                        </LoadingButton>
                    </form>

                    {/* <div>
                        <h3>Validation Model</h3>
                        <pre>{JSON.stringify(formik.errors, null, 2)}</pre>
                    </div>
                    <div>
                        <h3>Form Model</h3>
                        <pre>{JSON.stringify(formik.values, null, 2)}</pre>
                    </div> */}
                </Box >
            </Card>
        </Container>
    );
})