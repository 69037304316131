import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Button,
  DialogActions,
  SelectChangeEvent,
} from '@mui/material';
import { observer } from "mobx-react-lite"; 
import { LoadingButton } from '@mui/lab';
import { useStore } from '../../../stores/store';
import { format } from 'date-fns'
import { SchedulePlannerGroup } from '../../../models/Schedule/Planner/SchedulePlannerGroup';

interface SelectRoleDialogProps {
    open: boolean;
    addedGroup:SchedulePlannerGroup;
    onClose: (group: SchedulePlannerGroup) => void;
}

export default observer(function SchedulePlannerAddNewGroupDialog(props: SelectRoleDialogProps) {
  const { userStore, scheduleStore, shiftStore } = useStore();
  const { getCompanyRoles, loadCompanyRoles } = userStore;
  const { loadUnitSetups, getUnitSetups } = scheduleStore;
  const { loadShiftSetupsForRole, getShiftSetupsForRole } = shiftStore;
  const [role, setRole] = React.useState('');
  const [shift, setShift] = React.useState('');
  const [unit, setUnit] = React.useState('');
  const {onClose, open} = props;

    useEffect(() => {
        loadUnitSetups();
        loadCompanyRoles();
    }, []);

    const handleRoleChange = (event: SelectChangeEvent<string>) =>{
      setRole(event.target.value);
      let r = JSON.parse(event.target.value);
      loadShiftSetupsForRole(Number.parseInt(r.roleid));
    }
    const handleUnitChange = (event: SelectChangeEvent<string>) =>{
      setUnit(event.target.value);      
    }
    const handleShiftChange = (event: SelectChangeEvent<string>) =>{
      setShift(event.target.value);
    }

    const handleSaveClick = () =>{
      if(role == '' || shift == ''){
        console.log('error missing required fields');
        return;
      }
      let r = JSON.parse(role);
      let s = JSON.parse(shift);      
      let unitid = -1;
      let unitname = ''
      if(unit != ''){
        let u = JSON.parse(unit);
        unitid = u.unitid;
        unitname = u.unitname;
      }

      console.log('ok ' + r.role);
      let g : SchedulePlannerGroup = {cono:'',plannerid:0,groupid:0,groupname:'',roleid:r.roleid,rolename:r.role,
                      shiftid:s.shiftid,
                      shiftdescr:s.shifttime + ' ' + s.shiftdescr,
                      unitid:unitid,unitname:unitname};
      setRole('');
      setShift('');
      setUnit('');
      onClose(g);
    }

    const handleCloseClick = () =>{
      let g :SchedulePlannerGroup = {cono:'',plannerid:-1,groupid:-1,groupname:'',roleid:-1,rolename:'',
        shiftid:-1,shiftdescr:'',unitid:-1,unitname:''};

      onClose(g);
    }
    
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add New Group</DialogTitle>
      <DialogContent dividers={true}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, pt: 2 }}>
          <FormControl fullWidth>
            <InputLabel>Role*</InputLabel>
            <Select
              value={role}
              label="Role"
              onChange={(e) => handleRoleChange(e)}
            >
              {getCompanyRoles.map((role) => (
                <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                  {role.role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Shift*</InputLabel>
            <Select
              value={shift}
              label="Shift"
              onChange={(e) => handleShiftChange(e)}
            >
              {getShiftSetupsForRole.map((shift) => (
                <MenuItem key={shift.shiftid} value={JSON.stringify({ shiftid: shift.shiftid, shiftdescr: shift.descr,shifttime: format(new Date(shift.shiftstarttime), "hh:mm a") + ' - ' + format(new Date(shift.shiftendtime), "hh:mm a")})}>
                  {format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")} - {shift.descr}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel>Unit</InputLabel>
            <Select
              value={unit}
              label="Unit"
              onChange={(e) => handleUnitChange(e)}
            >
              {getUnitSetups.map((unit) => (
                <MenuItem key={unit.unitid} value={JSON.stringify({ unitid: unit.unitid, unitname: unit.unitname })}>
                  {unit.unitname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
                    <Box display="flex" justifyContent="end" width="100%">
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={() => handleCloseClick()}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} onClick={() => handleSaveClick()} variant='contained'>Add Group</LoadingButton>
                        </Box>
                    </Box>
                </DialogActions>
    </Dialog>
  );
})