import agent from '../api/agent';
import { makeAutoObservable, runInAction } from "mobx";
import { NewUserShiftInSchedule } from '../models/Schedule/NewUserShiftInSchedule';
import { User } from '../models/User';
import { RotationSetup } from '../models/Schedule/RotationSetup';
import { RotationDetail } from '../models/Schedule/RotationDetail';
import { NewUserInRotation } from '../models/Schedule/NewUserInRotation';
import { NewShiftInRotation } from '../models/Schedule/NewShiftInRotation';
import { RemoveShiftFromRotationDTO } from '../models/Schedule/RemoveShiftFromRotationDTO';
import { ChangeUserInRotationDTO } from '../models/Schedule/ChangeUserInRotationDTO';
import { UnitSetup } from '../models/Schedule/UnitSetup';
import { RotationDetailNew } from '../models/Schedule/RotationDetailNew';
import { CurrentSchedule } from '../models/Schedule/CurrentSchedule';
import { ScheduleTotalsByCompany } from '../models/Schedule/ScheduleTotalsByCompany';
import { RemoveShiftFromScheduleDTO } from '../models/Schedule/RemoveShiftFromScheduleDTO';
import { EditUserShiftInScheduleDTO } from '../models/Schedule/EditUserShiftInScheduleDTO';
import { EmptyUsersInScheduleDTO } from '../models/Schedule/EmptyUsersInScheduleDTO';
import { UnitSetupDTO } from '../models/Setups/UnitSetupDTO';
import { CompanyRolePPD } from '../models/Setups/CompanyRolePPD';
import { ScheduledWeeklyTotals } from '../models/Schedule/ScheduledWeeklyTotals';
import { RemoveUserFromWeekScheduleDTO } from '../models/Schedule/RemoveUserFromWeekScheduleDTO';
import ScheduledShiftInfoModel, { ScheduledShiftInfo } from '../models/Schedule/ScheduledShiftInfo';
import { BatchAddUsersToShiftsDTO } from '../models/Schedule/BatchAddUsersToShiftsDTO';
import { CopyWeeklyScheduleToNewWeekDTO } from '../models/Schedule/CopyWeeklyScheduleToNewWeekDTO';
import { ShiftKeyHours } from '../models/ShiftKey/ShiftKeyHours';
import { ShiftDifferentialRange } from '../models/ShiftDifferentials/ShiftDifferentialRange';
import { ShiftDifferentialCopyToDOW } from '../models/ShiftDifferentials/ShiftDifferentialCopyToDOW';
import { SchedulePlannerSetup } from '../models/Schedule/Planner/SchedulePlannerSetup';
import { SchedulePlannerGroup } from '../models/Schedule/Planner/SchedulePlannerGroup';
import { SchedulePlannerUser } from '../models/Schedule/Planner/SchedulePlannerUser';
import { SchedulePlannerSaveModel } from '../models/Schedule/Planner/SchedulePlannerSaveModel';

export default class ScheduleStore {
    usersByRoleAndDate = new Map<string, User>();
    rotationSetups = new Map<number, RotationSetup>();
    rotationDetails = new Map<string, RotationDetailNew>();
    currentSchedule = new Map<string, CurrentSchedule>();
    shiftKeyHours = new Map<string, ShiftKeyHours>();
    shiftDifferentials = new Map<string, ShiftDifferentialRange>();
    scheduleTotalsByCompany = new Map<string, ScheduleTotalsByCompany>();
    scheduledWeeklyTotals = new Map<string, ScheduledWeeklyTotals>();
    unitSetups = new Map<string, UnitSetup>();
    rotationRoles = new Map<string, string>();
    schedulePlannerSetups = new Map<string, SchedulePlannerSetup>();
    schedulePlannerGroups = new Map<string, SchedulePlannerGroup>();
    schedulePlannerUsers = new Map<string, SchedulePlannerUser>();
    scheduleshiftinfo: ScheduledShiftInfoModel | undefined = undefined;
    loading = false;
    loadingDialog = false;
    loadingDetails = false;
    savingData = false;

    constructor() {
        makeAutoObservable(this);
    }
    


    putAddUserShiftsToSchedule = async (user: NewUserShiftInSchedule) => {
        this.loadingDetails = true;
        let success = false;
        console.log('try to save shift to schedule');
        try {
            await agent.Schedule.putaddusershiftstoschedule(user);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }
    putNewUnit = async (user: UnitSetupDTO) => {
        this.loadingDetails = true;
        let success = false;
        console.log('try to save new unit');
        try {
            await agent.Schedule.putnewunit(user);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }
    putEditUnit = async (user: UnitSetupDTO) => {
        this.loadingDetails = true;
        let success = false;
        console.log('try to save edit unit');
        try {
            await agent.Schedule.puteditunit(user);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }
    putNewCompanyPPD = async (ppd: CompanyRolePPD) => {
        this.loadingDetails = true;
        let success = false;
        console.log('try to save new ppd');
        try {
            await agent.Schedule.putnewcompanyppd(ppd);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }
    putEditCompanyPPD = async (ppd: CompanyRolePPD) => {
        this.loadingDetails = true;
        let success = false;
        console.log('try to save edit ppd');
        try {
            await agent.Schedule.puteditcompanyppd(ppd);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }
    public clearShiftInfo = () =>{
        this.scheduleshiftinfo = undefined;
    }
    loadUsersForRoleAndDate = async (roleid:number, fromdate: string) => {
        console.log('getting users by role');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDialog = true;
        this.usersByRoleAndDate.clear();
        try {
            const users = await agent.Schedule.getusersavailableforroleanddate(cono, roleid, fromdate);
            users.forEach(u => {
                this.setUser(u);
            })
            this.loadingDialog = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDialog = false;
        }
        console.log(this.usersByRoleAndDate.size);
    }
    private setUser = (user: User) => {
        user.isSelected = true;
        this.usersByRoleAndDate.set(user.username.toString(), user);
    }
    get getUsersByRoleAndDate() {
        return Array.from(this.usersByRoleAndDate.values());
    }

    putRotationShiftsInSchedule = async (shifts: RotationDetailNew[], sunday: Date) => {
        this.loading = true;
        shifts.forEach((shift) =>{
            shift.sundaydate = sunday;
        })
        let success = false;
        try {
            await agent.Schedule.putrotationshiftsinschedule(shifts);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    putNewRotationSetup = async (rot: RotationSetup) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.putnewrotationsetup(rot);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    putNewUserInRotation = async (rot: NewUserInRotation) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.putnewuserinrotation(rot);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    putNewShiftInRotation = async (rot: NewShiftInRotation) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.putnewshiftinrotation(rot);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    removeShiftFromRotation = async (rot: RemoveShiftFromRotationDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.removeshiftfromrotation(rot);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    changeUserInRotation = async (rot: ChangeUserInRotationDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.changeuserinrotation(rot);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    loadRotationSetups = async () => {
        console.log('getting rotation setups');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.rotationSetups.clear();
        try {
            const rots = await agent.Schedule.getrotationsetups(cono);
            rots.forEach(u => {
                this.setRotationSetup(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }
        console.log(this.rotationSetups.size);
    }
    private setRotationSetup = (rot: RotationSetup) => {
        this.rotationSetups.set(rot.rotationid, rot);
    }
    get getRotationSetups() {
        return Array.from(this.rotationSetups.values());
    }

    loadUnitSetups = async () => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.unitSetups.clear();
        try {
            const rots = await agent.Schedule.getunitsforcompany(cono);
            rots.forEach(u => {
                this.setUnitSetup(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }
        console.log(this.rotationSetups.size);
        this.unitSetups.forEach(r => {
            r.isselected = true;
        })
    }
    private setUnitSetup = (u: UnitSetup) => {
        this.unitSetups.set(u.unitid.toString(), u);
    }
    get getUnitSetups() {
        return Array.from(this.unitSetups.values());
    }
    setCompanyUnitSelected = async (unit: UnitSetup) => {
        if (!this.unitSetups || !unit) {
            return;
        }
        let r = this.unitSetups.get(unit.unitid.toString());
        if (r != undefined) {
            r.isselected = true;
            this.unitSetups.set(r.unitid.toString(), r);
        }
    }
    setCompanyUnitUnSelected = async (unit: UnitSetup) => {
        if (!this.unitSetups || !unit) {
            return;
        }
        let r = this.unitSetups.get(unit.unitid.toString());
        if (r != undefined) {
            r.isselected = false;
            this.unitSetups.set(r.unitid.toString(), r);
        }
    }

    loadRotationDetails = async (rotationid: number) => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        this.rotationDetails.clear();
        this.rotationRoles.clear();
        try {
            const rots = await agent.Schedule.getrotationsforasetup(cono, rotationid);
            rots.forEach(u => {
                this.setRotationDetails(u);
                
            })
            this.loading = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loading = false;
        }
    }
    private setRotationDetails = (rot: RotationDetailNew) => {
        this.rotationDetails.set(rot.cono + rot.roleid + rot.username + rot.rotationid, rot);
        this.rotationRoles.set(rot.role,rot.role);
    }
    get getRotationDetails() {
        return Array.from(this.rotationDetails.values());
    }
    get getRotationRoles(){
        return Array.from(this.rotationRoles.values());
    }

    loadCurrentSchedule = async (d: string) => {
        console.log('getting schedule');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        this.currentSchedule.clear();
        try {
            const rots = await agent.Schedule.getcurrentschedule(cono, d);
            rots.forEach(u => {
                this.setCurrentSchedule(u);
            })
            this.loading = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loading = false;
        }
    }
    private setCurrentSchedule = (schedule: CurrentSchedule) => {
        this.currentSchedule.set(schedule.username + schedule.roleid.toString(),schedule);
    }
    get getCurrentSchedule() {
        return Array.from(this.currentSchedule.values());
    }

    loadScheduleWeeklyTotals = async (d: string) => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDialog = true;
        this.scheduledWeeklyTotals.clear();
        console.log('getting week shift hours for ' + d);
        try {
            const rots = await agent.Schedule.getscheduleweeklytotals(cono, d);
            rots.forEach(u => {
                this.setScheduleWeeklyTotals(u);
            })
            this.loadingDialog = false;
        } catch (error) {
            this.loadingDialog = false;
        }
    }
    private setScheduleWeeklyTotals = (total: ScheduledWeeklyTotals) => {
        this.scheduledWeeklyTotals.set(total.role.toString(),total);
    }
    get getScheduleWeeklyTotals() {
        return Array.from(this.scheduledWeeklyTotals.values());
    }

    loadScheduleTotals = async (sundaydate: string) => {
        if (!window.localStorage.getItem('user')) {
            return;
        }
        let username = window.localStorage.getItem('user');
        if (!username) {
            return;
        }
        this.loading = true;
        this.scheduleTotalsByCompany.clear();
        try {
            const rots = await agent.Schedule.getscheduletotalsbycompany(username,sundaydate);
            rots.forEach(u => {
                this.setScheduleTotals(u);
            })
            this.loading = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loading = false;
        }
        console.log(this.scheduleTotalsByCompany.values.length);
    }
    private setScheduleTotals = (schedule: ScheduleTotalsByCompany) => {
        console.log('getting schedule totals' + schedule.roleid + ' size' + this.scheduleTotalsByCompany.size);
        this.scheduleTotalsByCompany.set(schedule.cono.toString() + schedule.role.toString(),schedule);
        console.log('getting schedule2totals' + schedule.roleid + ' size' + this.scheduleTotalsByCompany.size);
    }
    get getScheduleTotals() {
        return Array.from(this.scheduleTotalsByCompany.values());
    }
    removeUserFromWeekSchedule = async (user: RemoveUserFromWeekScheduleDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.removeuserfromweekschedule(user);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    removeShiftFromSchedule = async (shift: RemoveShiftFromScheduleDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.removeshiftfromschedule(shift);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    putEditUserShiftInSchedule = async (shift: EditUserShiftInScheduleDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.puteditusershiftinschedule(shift);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    putEmptyUserInSchedule = async (shift: EmptyUsersInScheduleDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.putemptyuserinschedule(shift);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    loadShiftInfoByKey = async (username: string, id: number) => {
        this.loadingDetails = true;
        try {
            let cono = window.localStorage.getItem('cono');
            if (!cono) {
                return;
            }
            await agent.Schedule.getscheduleshiftinfo(cono, username, id).then((info) =>{
                // runInAction(() => {
                    this.scheduleshiftinfo = info;
                // })
            });
            this.loadingDetails = false;
            // alert(JSON.stringify(this.scheduleshiftinfo, null, 2));
            return this.scheduleshiftinfo;
        } catch (error) {
            console.log('error 53' + error);
            this.loadingDetails = false;
        }
    }

    putBatchAddUserShiftsToSchedule = async (shift: BatchAddUsersToShiftsDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.putbatchaddusershiftstoschedule(shift);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    copyWeeklyScheduleToNewWeek = async (shift: CopyWeeklyScheduleToNewWeekDTO) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Schedule.copyweeklyscheduletonewweek(shift);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    
    loadShiftKeyHours = async () => {        
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.shiftKeyHours.clear();
        try {
            const rots = await agent.Schedule.getshiftkeyhours(cono);
            rots.forEach(u => {
                this.setShiftKeyHours(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }
        
    }
    private setShiftKeyHours = (u: ShiftKeyHours) => {
        this.shiftKeyHours.set(this.shiftKeyHours.size.toString(), u);
    }
    get getShiftKeyHours() {
        return Array.from(this.shiftKeyHours.values());
    }

    loadShiftDifferentialsForRole = async (roleid: number, secondaryroleid: number) => {        
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        this.shiftDifferentials.clear();
        try {
            const rots = await agent.Schedule.getshiftdifferentialsforrole(cono, roleid, secondaryroleid);
            rots.forEach(u => {
                this.setShiftDifferentialsForRole(u);
            })
            this.loading = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loading = false;
        }
        
    }
    private setShiftDifferentialsForRole = (u: ShiftDifferentialRange) => {
        this.shiftDifferentials.set(this.shiftDifferentials.size.toString(), u);
    }
    get getShiftDifferentialsForRole() {
        return Array.from(this.shiftDifferentials.values());
    }

    putShiftDifferential = async (shiftdiff: ShiftDifferentialRange) => {
        this.loadingDetails = true;
        let success = false;
        try {
            await agent.Schedule.putshiftdifferential(shiftdiff);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }

    putRemoveShiftDifferential = async (shiftdiff: ShiftDifferentialRange) => {
        this.loadingDetails = true;
        let success = false;
        try {
            await agent.Schedule.putremoveshiftdifferential(shiftdiff);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }

    putShiftDifferentialCopyToDOW = async (shiftdiff: ShiftDifferentialCopyToDOW) => {
        this.loadingDetails = true;
        let success = false;
        try {
            await agent.Schedule.putshiftdifferentialcopytodow(shiftdiff);
            success = true;
            this.loadingDetails = false;
            return success;
        }
        catch (error) {
            this.loadingDetails = false;
            return success;
        }
    }

    loadSchedulePlannerSetups = async () => {
        console.log('getting planner setups');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.schedulePlannerSetups.clear();
        try {
            const rots = await agent.Schedule.getscheduleplannersetups(cono);
            rots.forEach(u => {
                this.setSchedulePlannerSetup(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }
    }
    private setSchedulePlannerSetup = (s: SchedulePlannerSetup) => {
        this.schedulePlannerSetups.set(s.plannerid.toString(), s);
    }
    get getSchedulePlannerSetups() {
        return Array.from(this.schedulePlannerSetups.values());
    }
    
    loadSchedulePlannerGroups = async (plannerid:number) => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.schedulePlannerGroups.clear();
        try {
            const rots = await agent.Schedule.getscheduleplannergroups(cono,plannerid);
            rots.forEach(u => {
                this.setSchedulePlannerGroup(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }       
    }
    private setSchedulePlannerGroup = (s: SchedulePlannerGroup) => {
        this.schedulePlannerGroups.set(s.groupid.toString(), s);
    }
    get getSchedulePlannerGroupsAll() {
        return Array.from(this.schedulePlannerGroups.values());
    }
    
    loadSchedulePlannerUsers = async (plannerid:number) => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingDetails = true;
        this.schedulePlannerUsers.clear();
        try {
            const rots = await agent.Schedule.getscheduleplannerusers(cono,plannerid);
            rots.forEach(u => {
                this.setSchedulePlannerUser(u);
            })
            this.loadingDetails = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingDetails = false;
        }
        console.log('got users ' + this.schedulePlannerUsers.size);
        console.log('got users and ' + this.schedulePlannerUsers.values.length);
    }
    private setSchedulePlannerUser = (s: SchedulePlannerUser) => {
        this.schedulePlannerUsers.set(s.groupid.toString() + s.username, s);
    }
    get getSchedulePlannerUsers() {
        return Array.from(this.schedulePlannerUsers.values());
    }

    putSchedulePlannerChanges = async (s: SchedulePlannerSaveModel) => {
        this.savingData = true;
        let success = false;
        try {
            await agent.Schedule.putscheduleplanner(s);
            success = true;
            this.savingData = false;
            return success;
        }
        catch (error) {
            this.savingData = false;
            return success;
        }
    }

    applySchedulePlannerToSchedule = async (s: SchedulePlannerSaveModel) => {
        this.savingData = true;
        let success = false;
        try {
            await agent.Schedule.applyscheduleplannertoschedule(s);
            success = true;
            this.savingData = false;
            return success;
        }
        catch (error) {
            this.savingData = false;
            return success;
        }
    }
    putNewSchedulePlannerSetup = async (s: SchedulePlannerSaveModel) => {
        this.savingData = true;
        let success = false;
        try {
            await agent.Schedule.putnewscheduleplannersetup(s);
            success = true;
            this.savingData = false;
            return success;
        }
        catch (error) {
            this.savingData = false;
            return success;
        }
    }
}