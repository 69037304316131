import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Button,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  Stack,
  DialogActions
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const SchedulePlannerCheckboxComponent = (props: DialogProps) => {
  const { onClose, open } = props;
  const [startPosition, setStartPosition] = useState(0);
  const numbers = Array.from({ length: 31 }, (_, i) => i + 1);

  const handleClose = () => {
    onClose();
  };

  const shouldShowX = (index: number) => {
    const adjustedPosition = (index - startPosition) % 6;
    // Handle negative numbers correctly
    const normalizedPosition = adjustedPosition < 0 ? adjustedPosition + 6 : adjustedPosition;
    return normalizedPosition < 4;
  };

  const shiftLeft = () => {
    setStartPosition((prev) => prev - 1);
  };

  const shiftRight = () => {
    setStartPosition((prev) => prev + 1);
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth maxWidth='xl'>
      <DialogTitle>Apply Shifts</DialogTitle>
      <DialogContent>
        <>
          <Box sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
            <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                onClick={shiftLeft}
                sx={{ marginRight: 1, marginBottom: 1 }}
                variant='outlined'
              >
                <ChevronLeft />
              </Button>
              <Button
                onClick={shiftRight}
                sx={{ marginLeft: 1, marginBottom: 1 }}
                variant='outlined'
              >
                <ChevronRight />
              </Button>
            </Stack>
          </Box>

          <TableContainer>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  {numbers.map((num, index) => (
                    <TableCell>
                      <Typography fontSize={12}>{num}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {numbers.map((num, index) => (
                  <TableCell>
                    <Typography fontSize={9}>{shouldShowX(index) ? 'X' : ''}</Typography>
                  </TableCell>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          autoFocus onClick={() => handleClose()}
          sx={{ margin: 1, borderRadius: 5 }}>
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          autoFocus 
          sx={{ margin: 1, borderRadius: 5 }}>
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SchedulePlannerCheckboxComponent;