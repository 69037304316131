import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/system';
import { useStore } from "../../../app/stores/store";
import { format } from 'date-fns'
import { Box, Button, LinearProgress } from "@mui/material";
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default observer(function RoleSetupList() {
    const { userStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles, loading } = userStore;

    useEffect(() => {
        loadCompanyRoles();
    }, []);

if(loading) return(
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <h1 style={{
            textAlign: 'center', fontSize: '18',
            fontWeight: 700,
            color: 'inherit',
        }}>ROLE SETUP</h1>
        <Box sx={{ width: '100%' }}>
            <LinearProgress color="secondary" />
        </Box>
    </Stack>
</div>
)

    return (
        <>
            <Stack marginLeft={2} marginRight={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: 55 }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <h1 style={{
                            textAlign: 'center', fontSize: '18',
                            fontWeight: 700,
                            color: 'inherit',
                        }}>ROLE SETUP</h1>
                        {/* <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box> */}
                    </Stack>
                </div>
                <TableContainer component={Paper} sx={{ margin: 2 }}>
                    <Table sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                        <TableHead>
                            <StyledTableRow key="100">
                                <StyledTableCell align="center">Role</StyledTableCell>
                                <StyledTableCell align="center">Job Description(s)</StyledTableCell>
                                <StyledTableCell width={200}></StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            {getCompanyRoles && getCompanyRoles.map((role) =>
                                <StyledTableRow key={role.roleid}>
                                    <StyledTableCell align="center">{role.role}</StyledTableCell>
                                    <StyledTableCell align="center">{role.secondaryroles}</StyledTableCell>
                                    <StyledTableCell width={200}>
                                        <Button
                                            sx={{ margin: 1, borderRadius:5  }}
                                            variant="contained"
                                            color="secondary"
                                            component={Link} to={'/editrole/' + role.roleid}>
                                            Edit Role
                                        </Button>
                                    </StyledTableCell>
                                </StyledTableRow >
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Button
                    sx={{ margin: 1, borderRadius:5  }}
                    variant="contained"
                    color="secondary"
                    component={Link} to="/addnewrole"
                >
                    Create New Role
                </Button>
            </Stack>
        </>
    );
})