import { styled, TableCell, tableCellClasses, TableRow, TableContainer, Table, TableHead, Stack, Typography, TableBody, ToggleButtonGroup, ToggleButton, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Box, IconButton, Input, InputAdornment, Tooltip, Dialog, DialogContent, DialogTitle, Button, DialogActions, Divider, FormControl, LinearProgress } from "@mui/material";
import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useStore } from "../../stores/store";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import ShiftDiffComponent from "./ShiftDiffComponent";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ShiftDifferentialRange } from "../../models/ShiftDifferentials/ShiftDifferentialRange";
import { SecondaryRole } from "../../models/Setups/SecondaryRole";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { format, isWeekend } from "date-fns";
import { ShiftDifferentialCopyToDOW } from "../../models/ShiftDifferentials/ShiftDifferentialCopyToDOW";
import { LoadingButton } from "@mui/lab";
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#ebebeb ',
        //backgroundColor: theme.palette.action.hover,
    },

}));

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
        <Draggable
            nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
            handle="#draggable-dialog-title"
            cancel={'[class*="MuiDialogContent-root"]'}
        >
            <Paper {...props} ref={nodeRef} />
        </Draggable>
    );
}

export default observer(function ShiftDifferentials() {
    const [alignment, setAlignment] = React.useState<string | null>('left');
    const { userStore, scheduleStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles, loadCompanySecondaryRoles, getCompanySecondaryRoles } = userStore;
    const { loadShiftDifferentialsForRole, getShiftDifferentialsForRole, putRemoveShiftDifferential,
        putShiftDifferentialCopyToDOW, loading, loadingDetails } = scheduleStore;
    const [selectedroleobject, setSelectedRoleObject] = React.useState('');
    const [selectedroledescrobject, setSelectedRoleDescrObject] = React.useState('');
    const [selectedRoleID, setSelectedRoleID] = React.useState(-1);
    const [selectedSecondaryRoleID, setSelectedSecondaryRoleID] = React.useState(-1);
    const [addDifferentialOpen, setAddDifferentialOpen] = React.useState(false);
    const [selectedDOW, setSelectedDOW] = React.useState('');
    const [selectedDayCopyToDOW, setSelectedDayCopyToDOW] = React.useState('');
    const [openCopyToDOW, setOpenCopyToDOW] = React.useState(false);

    const handleSaveCopyToDOW = () => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let role = JSON.parse(selectedroleobject);
        let role2 = JSON.parse(selectedroledescrobject);
        let s: ShiftDifferentialCopyToDOW = {
            cono: cono, roleid: role.roleid, secondaryroleid: role2.secondaryroleid,
            copyfromdow: selectedDOW, copytodow: selectedDayCopyToDOW
        }
        putShiftDifferentialCopyToDOW(s).then(() => {
            setOpenCopyToDOW(false);
            if (selectedroleobject != null && selectedroledescrobject != null) {
                let role = JSON.parse(selectedroleobject);
                let role2 = JSON.parse(selectedroledescrobject);
                loadShiftDifferentialsForRole(role.roleid, role2.secondaryroleid);
            }
        });
    };

    const handleCancelCopyToDOW = () => {
        setOpenCopyToDOW(false);
    };
    const onCloseCopyToDOW = () => {
        setOpenCopyToDOW(false);
    };

    useEffect(() => {
        document.title = 'Shift Differentials';
        loadCompanyRoles();
        loadCompanySecondaryRoles();
    }, []);

    const handleOpenAddNewDifferential = (dow: string) => {
        let role = JSON.parse(selectedroleobject);
        let role2 = JSON.parse(selectedroledescrobject);
        setSelectedRoleID(role.roleid);
        setSelectedSecondaryRoleID(role2.secondaryroleid);
        setSelectedDOW(dow);
        setAddDifferentialOpen(true);
    }

    const handleRemoveShiftDifferential = (roleid: number, secondaryroleid: number, dow: string, startTime: string) => {
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let s: ShiftDifferentialRange = {
            cono: cono, roleid: roleid, secondaryroleid: secondaryroleid,
            dow: dow, starttime: startTime, endtime: '', differential: 0
        };
        putRemoveShiftDifferential(s).then(() => {
            if (selectedroleobject != null && selectedroledescrobject != null) {
                console.log(selectedroleobject);
                console.log(selectedroledescrobject);
                let role = JSON.parse(selectedroleobject);
                let role2 = JSON.parse(selectedroledescrobject);
                loadShiftDifferentialsForRole(role.roleid, role2.secondaryroleid);
            }
        });
    }

    const handleClose = (dorefresh: boolean) => {
        setAddDifferentialOpen(false);
        if (selectedroleobject != null && selectedroledescrobject != null) {
            console.log(selectedroleobject);
            console.log(selectedroledescrobject);
            let role = JSON.parse(selectedroleobject);
            let role2 = JSON.parse(selectedroledescrobject);
            loadShiftDifferentialsForRole(role.roleid, role2.secondaryroleid);
        }
    }
    type WeeklyShiftDifferentials = {
        [dayOfWeek: string]: ShiftDifferentialRange[];
    };

    const [shiftDifferentials, setShiftDifferentials] = React.useState<WeeklyShiftDifferentials>({
        SUNDAY: [],
        MONDAY: [],
        TUESDAY: [],
        WEDNESDAY: [],
        THURSDAY: [],
        FRIDAY: [],
        SATURDAY: []
    });

    // const addShiftDifferential = (day: string) => {
    //     setShiftDifferentials(prev => ({
    //         ...prev,
    //         [day]: [...(prev[day] || []), {
    //             from: -1,
    //             thru: -1,
    //             differential: -1
    //         }]
    //     }));
    // };
    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment !== null) {
            setAlignment(newAlignment);
        }
    };

    function filterselectedrole(r: SecondaryRole) {
        let role = JSON.parse(selectedroleobject);
        if (r.roleid == role.roleid) {
            return true;
        }
        else {
            return false;
        }
    }

    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedRoleObject(event.target.value.toString());
        setSelectedRoleDescrObject('');
        // let role = JSON.parse(event.target.value.toString());
        // if (role != null && role.roleid > -1) {
        //     formik.values.assignedUsers = [];
        // }
    };
    const handleRoleDescrChange = (event: SelectChangeEvent<string>) => {
        console.log(event.target.value.toString());
        setSelectedRoleDescrObject(event.target.value.toString());
        // let role = JSON.parse(event.target.value.toString());
        // if (role != null && role.roleid > -1) {
        //     formik.values.assignedUsers = [];
        // }
        if (selectedroleobject != null && selectedroledescrobject != null) {
            console.log(selectedroleobject);
            console.log(selectedroledescrobject);
            let role = JSON.parse(selectedroleobject);
            let role2 = JSON.parse(event.target.value.toString());
            loadShiftDifferentialsForRole(role.roleid, role2.secondaryroleid);
        }
    };
    const daysofweek: string[] = [
        "SUNDAY",
        "MONDAY",
        "TUESDAY",
        "WEDNESDAY",
        "THURSDAY",
        "FRIDAY",
        "SATURDAY"
    ];

    const handleCopyDayToDay = (dow: string) => {
        setSelectedDOW(dow);
        setOpenCopyToDOW(true);
    }
    const checkDOWForValidDifferentials = (dow: number) => {
        let result = false;

        return result;
    }

    return (
        <>
            <Stack>
                <Typography variant='h4' fontSize={22} align='center' textAlign="center" sx={{ fontWeight: 'bold', marginTop: 1 }}>
                    Shift Differentials
                </Typography>
                <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                    <InputLabel id="role-label" sx={{ marginTop: 1 }}>Role:</InputLabel>
                    <Select
                        labelId='role-label'
                        size='small'
                        value={selectedroleobject}
                        onChange={handleRoleChange}
                        sx={{ display: 'block', width: 180, marginTop: 1, marginLeft: 1 }}>
                        {getCompanyRoles.map((role) => (
                            <MenuItem key={role.roleid.toString() + role.secondaryroles.toString()} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                {role.role}
                            </MenuItem>
                        ))}
                    </Select>

                    {selectedroleobject != '' ?
                        <>
                            <InputLabel id="role-label" sx={{ marginTop: 1, marginLeft: 3 }}>Job Description:</InputLabel>
                            <Select
                                labelId='role-label'
                                size='small'
                                value={selectedroledescrobject}
                                onChange={handleRoleDescrChange}
                                sx={{ display: 'block', width: 180, marginTop: 1, marginLeft: 1 }} >
                                {getCompanySecondaryRoles.filter(filterselectedrole).map((role) => (
                                    <MenuItem key={role.secondaryroleid.toString() + role.roleid.toString()} value={JSON.stringify({ roleid: role.roleid, secondaryroleid: role.secondaryroleid, role: role.role })}>
                                        {role.secondaryrolename}
                                    </MenuItem>
                                ))}
                            </Select></>
                        : <></>}
                </Stack>
                {/* <ToggleButtonGroup
                sx={{margin:1,alignContent:'center',alignItems:'center',alignSelf:'center'}}
                    value={alignment}
                    exclusive        
                    color="primary"            
                    onChange={handleAlignment}
                    aria-label="text alignment"
                >
                    <ToggleButton value="left" aria-label="left aligned">
                        <Typography fontWeight={'bold'}>AM</Typography>
                    </ToggleButton>
                    <ToggleButton value="center" aria-label="centered">
                    <Typography fontWeight={'bold'}>PM</Typography>
                    </ToggleButton>
                </ToggleButtonGroup> */}
                {!loading && selectedroleobject != '' && selectedroledescrobject != '' ?
                    <TableContainer component={Paper} sx={{ marginTop: 1, maxHeight: 580 }}>
                        <Table stickyHeader={true} sx={{ minWidth: 300 }} size='small' aria-label="simple table">
                            <TableHead>
                                <StyledTableRow key={1}>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(0) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                SUNDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('SUNDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(1) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                MONDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('MONDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(2) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                TUESDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('TUESDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(3) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                WEDNESDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('WEDNESDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(4) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                THURSDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('THURSDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(5) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                FRIDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('FRIDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                    <StyledTableCell align="center">
                                        <Stack direction={'row'} alignContent={'center'} alignItems={'center'} alignSelf={'center'} sx={{ justifyContent: 'center', alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {/* {checkDOWForValidDifferentials(6) ? <><Tooltip title="Valid Shift Differentials"><CheckCircleOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} /></Tooltip></>
                                                : <><Tooltip title="Invalid Shift Differentials"><ErrorOutlineIcon sx={{ fontSize: 18, marginRight: 1 }} color='warning' /></Tooltip></>} */}
                                            <Typography fontSize={14}>
                                                SATURDAY
                                            </Typography>
                                            <Tooltip title="Copy To Different Day">
                                                <IconButton size="small"
                                                    onClick={() => handleCopyDayToDay('SATURDAY')}
                                                    color="inherit"
                                                    sx={{ marginLeft: 1 }}
                                                >
                                                    <ContentCopyIcon sx={{ fontSize: 13 }} />
                                                </IconButton>
                                            </Tooltip>
                                        </Stack>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {/* <StyledTableRow key={2}>
                                    {Array.from(daysofweek).map((day, dayindex) => (
                                        <StyledTableCell key={-1} align='center'>
                                            <ShiftDiffComponent />
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                                <StyledTableRow key={3}>
                                    {Array.from(daysofweek).map((day, dayindex) => (
                                        shiftDifferentials[day].map((shift, index) => (
                                            <StyledTableCell key={-1} align='center'>
                                                <Box sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small">
                                                        <AddCircleIcon sx={{ fontSize: 11 }} color='success' />
                                                    </IconButton>
                                                </Box>
                                            </StyledTableCell>
                                        ))))}
                                </StyledTableRow> */}
                                <StyledTableRow key={3}>
                                    <StyledTableCell align='center' width="14%">
                                        <Box sx={{ alignContent: 'center', alignItems: 'center', alignSelf: 'center' }}>
                                            {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "SUNDAY").map((shift, index) =>
                                                <>
                                                    <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                        <Typography sx={{ fontSize: 11 }}>{shift.starttime.substring(0, 5)}-</Typography>
                                                        <Typography sx={{ fontSize: 11 }}> {shift.endtime.substring(0, 5)}</Typography>
                                                        <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                        <IconButton
                                                            aria-label="expand row"
                                                            size="small"
                                                            sx={{ alignSelf: 'end', marginLeft: 1 }}
                                                            onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'SUNDAY', shift.starttime)}
                                                        >
                                                            <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                        </IconButton>
                                                    </Stack>
                                                    {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "SUNDAY").length - 1 ? <Divider /> : <></>}</>
                                            )}
                                        </Box>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "MONDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'MONDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "MONDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "TUESDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'TUESDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "TUESDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "WEDNESDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'WEDNESDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "WEDNESDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "THURSDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'THURSDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "THURSDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "FRIDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end' }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'FRIDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "FRIDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        {getShiftDifferentialsForRole && getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "SATURDAY").map((shift, index) =>
                                            <>
                                                <Stack direction={'row'} sx={{ marginTop: 1, marginBottom: 1, alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.starttime}-</Typography>
                                                    <Typography sx={{ fontSize: 11 }}>{shift.endtime}</Typography>
                                                    <Typography sx={{ fontSize: 11 }} marginLeft={2}>${shift.differential}</Typography>
                                                    <IconButton
                                                        aria-label="expand row"
                                                        size="small"
                                                        sx={{ alignSelf: 'end', marginLeft: 2 }}
                                                        onClick={() => handleRemoveShiftDifferential(shift.roleid, shift.secondaryroleid, 'SATURDAY', shift.starttime)}
                                                    >
                                                        <DeleteIcon sx={{ fontSize: 14 }} color='error' />
                                                    </IconButton>
                                                </Stack>
                                                {index != getShiftDifferentialsForRole.filter(i => i.dow.toUpperCase() === "SATURDAY").length - 1 ? <Divider /> : <></>}</>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                                <StyledTableRow key={10000}>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('SUNDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('MONDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('TUESDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('WEDNESDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('THURSDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('FRIDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                    <StyledTableCell align='center' width="14%">
                                        <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            onClick={() => handleOpenAddNewDifferential('SATURDAY')}>
                                            <AddCircleIcon sx={{ fontSize: 14 }} color='success' />
                                        </IconButton>
                                    </StyledTableCell>
                                </StyledTableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    : <></>}

                {loading ?
                    <Box sx={{ width: '100%', marginTop: 2 }}>
                        <LinearProgress />
                    </Box> : <></>}
            </Stack>

            <ShiftDiffComponent open={addDifferentialOpen}
                onClose={handleClose} dow={selectedDOW} roleid={selectedRoleID}
                secondaryroleid={selectedSecondaryRoleID} />

            <React.Fragment>
                <Dialog PaperComponent={PaperComponent} open={openCopyToDOW} onClose={onCloseCopyToDOW} maxWidth="xs" fullWidth aria-labelledby="draggable-dialog-title">
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Copy Differentials To Day</DialogTitle>
                    <DialogContent dividers={true}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="day-select-label">Day of Week</InputLabel>
                            <Select
                                labelId="day-select-label"
                                value={selectedDayCopyToDOW}
                                label="Day of Week"
                                onChange={(e) => setSelectedDayCopyToDOW(e.target.value)}
                            >
                                {daysofweek.filter(d => d != selectedDOW).map((day) => (
                                    <MenuItem key={day} value={day}>
                                        {day}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Typography sx={{ fontSize: 10 }}>*This will remove any differentials set up for this day</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCancelCopyToDOW} color="primary">
                            Cancel
                        </Button>
                        <LoadingButton
                            onClick={handleSaveCopyToDOW}
                            color="primary"
                            variant="contained"
                            disabled={!selectedDayCopyToDOW}
                            loading={loadingDetails}
                        >
                            Save
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </>
    )
})