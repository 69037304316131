import axios, { AxiosResponse, AxiosError } from 'axios';
import NewShiftAlertModel from '../models/NewShiftAlert';
import { User } from '../models/User';
import { OpenShift } from '../models/OpenShift'
import { ShiftInfo } from '../models/ShiftInfo';
import NewUserModel from '../models/NewUser';
import { ShiftInfoByKeyModel } from '../models/ShiftInfoByKeyModel';
import { SentShiftFromHistory } from '../models/SentShiftFromHistory';
import { OpenShiftResponse } from '../models/OpenShiftReponse';
import { ShiftAlertResponse } from '../models/ShiftAlertResponse';
import UserLoginModel from '../models/UserLoginModel';
import { AuthenticatedUser } from '../models/AuthenticatedUser';
import { ShiftAlertInfoByID } from '../models/ShiftAlertInfoByID';
import ChangePasswordModel from '../models/ChangePasswordModel';
import { CompanyModel } from '../models/CompanyModel';
import { CompanyRole } from '../models/CompanyRole';
import ShiftSetupModel, { ShiftSetup } from '../models/ShiftSetup';
import CompanyRoleModel from '../models/CompanyRole';
import { UserShiftResponse } from '../models/UserShiftResponse';
import RegisterNewUserModel from '../models/RegisterNewUserModel';
import { VerifyUser } from '../models/Setups/VerifiyUser';
import { ShiftAlertStopResponses } from '../models/ShiftAlertStopResponses';
import { UsernameDTO } from '../models/Setups/UsernameDTO';
import { EmailAndTextUsage } from '../models/EmailAndTextUsage';
import ShiftsInfoByKeyModel from '../models/ShiftsInfoByKey';
import { SendShiftAlertForRoleModel } from '../models/SendShiftAlertForRole';
import CompanyRoleWithLastSentModel from '../models/CompanyRoleWithLastSent';
import UserConoAndPhoneModel, { UserConoAndPhone } from '../models/UserConoAndPhone';
import { UserShiftsByKey } from '../models/UserShiftsByKey';
import { UpdateUserDefaultCono } from '../models/UpdateUserDefaultCono';
import { ClaimedAlertEmailSetup } from '../models/Setups/ClaimedAlertEmailSetup';
import { ClaimedAlertTextSetup } from '../models/Setups/ClaimedAlertTextSetup';
import ShiftTemplateModel from '../models/Setups/ShiftTemplate';
import ShiftTemplateSetupModel from '../models/Setups/ShiftTemplateSetup';
import ShiftInTemplate from '../models/Setups/ShiftInTemplate';
import RemoveShiftFromTemplateModel from '../models/Setups/RemoveShiftTemplateTemplateModel';
import UserRolesModel from '../models/UserRoles';
import { UserLookupByPhoneModel } from '../models/Setups/UserLookupByPhone';
import AddUserToCompanyDTOModel from '../models/Setups/AddUserToCompanyDTO';
import AutoAssignUsersForTemplateShiftDTOModel from '../models/Setups/AutoAssignUsersForTemplateShiftDTO';
import AutoAssignedUserForTemplateModel from '../models/Setups/AutoAssignedUserForTemplate';
import UserSentHistoryModel from '../models/UserSentHistory';
import { UserShiftRecommendation } from '../models/UserShiftRecommendation';
import ContactFormModel from '../models/ContactForm';
import { ShiftAlertForSingleUser } from '../models/ShiftAlertForSingleUser';
import { history } from '../..';
import { UserShiftsGrouped } from '../models/UserShiftsGrouped';
import { NewUserShiftInSchedule } from '../models/Schedule/NewUserShiftInSchedule';
import { RotationSetup } from '../models/Schedule/RotationSetup';
import { NewUserInRotation } from '../models/Schedule/NewUserInRotation';
import { NewShiftInRotation } from '../models/Schedule/NewShiftInRotation';
import { RemoveShiftFromRotationDTO } from '../models/Schedule/RemoveShiftFromRotationDTO';
import { ChangeUserInRotationDTO } from '../models/Schedule/ChangeUserInRotationDTO';
import { UnitSetup } from '../models/Schedule/UnitSetup';
import { RotationDetailNew } from '../models/Schedule/RotationDetailNew';
import { CurrentSchedule } from '../models/Schedule/CurrentSchedule';
import { ScheduleTotalsByCompany } from '../models/Schedule/ScheduleTotalsByCompany';
import { RemoveShiftFromScheduleDTO } from '../models/Schedule/RemoveShiftFromScheduleDTO';
import { EditUserShiftInScheduleDTO } from '../models/Schedule/EditUserShiftInScheduleDTO';
import { EmptyUsersInScheduleDTO } from '../models/Schedule/EmptyUsersInScheduleDTO';
import { UnitSetupDTO } from '../models/Setups/UnitSetupDTO';
import { CompanyRolePPD } from '../models/Setups/CompanyRolePPD';
import { ScheduledWeeklyTotals } from '../models/Schedule/ScheduledWeeklyTotals';
import { RemoveUserFromWeekScheduleDTO } from '../models/Schedule/RemoveUserFromWeekScheduleDTO';
import ScheduledShiftInfoModel from '../models/Schedule/ScheduledShiftInfo';
import { BatchAddUsersToShiftsDTO } from '../models/Schedule/BatchAddUsersToShiftsDTO';
import { CopyWeeklyScheduleToNewWeekDTO } from '../models/Schedule/CopyWeeklyScheduleToNewWeekDTO';
import { ShiftKeyHours } from '../models/ShiftKey/ShiftKeyHours';
import { OpenShiftForNewKey } from '../models/OpenShiftForNewKey';
import { OpenShiftInfoForNewKey } from '../models/OpenShiftInfoForNewKey';
import { SecondaryRole } from '../models/Setups/SecondaryRole';
import { ShiftDifferentialRange } from '../models/ShiftDifferentials/ShiftDifferentialRange';
import { ShiftDifferentialCopyToDOW } from '../models/ShiftDifferentials/ShiftDifferentialCopyToDOW';
import { Announcement } from '../models/Announcement';
import { SchedulePlannerSetup } from '../models/Schedule/Planner/SchedulePlannerSetup';
import { SchedulePlannerGroup } from '../models/Schedule/Planner/SchedulePlannerGroup';
import { SchedulePlannerUser } from '../models/Schedule/Planner/SchedulePlannerUser';
import { SchedulePlannerSaveModel } from '../models/Schedule/Planner/SchedulePlannerSaveModel';

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
        setTimeout(resolve, delay)
    })
}

const url = 'https://api.fillopenshifts.com/api';
//const url = 'https://localhost:44302/api';

axios.defaults.baseURL = url;

const httpClient = axios.create({
    baseURL: url,
    // baseURL: process.env.APP_API_BASE_URL,
});

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('sec');
    console.log('working ' + token);
    config.headers!.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

axios.interceptors.response.use(async response => {

    if (process.env.NODE_ENV === 'development') await sleep(1000);
    return response;
}, (error: AxiosError) => {
    const data: any = error.response!.data;
    const status = error.response!.status;
    const config = error.response!.config;
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
                // history.push('/not-found');
            }
            if (data.errors) {
                const modalStateErrors = [];
                for (const key in data.errors) {
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
            } else {
                // toast.error(data);
            }
            break;
        case 401:
            console.log("auth expired, log in again");
            window.localStorage.clear();
            history.push("/login");
            history.go(0);
            break;
        case 404:
            // history.push('/not-found')
            // toast.error("Not Found");
            break;
        case 500:
            // store.commonStore.setServerError(data);
            // history.push('/server-error');
            break;
    }
})

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const config = {
    headers:{
        'Content-Type': 'application/json; charset=utf-8',
        'x-apiAuth': window.localStorage.getItem('sec')!
    }
  };

const requests = {
    get: <T>(url: string) => axios.get<T>(url, config).then(responseBody),
    post: <T>(url: string, body: {}) => axios.post<T>(url, body, config).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body, config).then(responseBody),
    del: <T>(url: string) => axios.delete<T>(url, config).then(responseBody),
}

const Shifts = {
    list: (cono: string) => requests.get<OpenShift[]>('/GetOpenShifts/' + cono),
    create: (shift: NewShiftAlertModel) => requests.post<void>('/CreateShiftAlert', shift),
    resendshiftalert: (shift: ShiftInfo) => requests.post<void>('/ResendShiftAlert', shift),
    shiftinfobykey: (cono: string, key: string) => requests.get<ShiftInfoByKeyModel>('GetShiftInfoByKey/' + cono + '/' + key),
    shiftsforrolebykey: (cono: string, key: string) => requests.get<OpenShift[]>('GetShiftsForRoleByKey/' + cono + '/' + key),
    shiftsinfoforrolebykey: (cono: string, key: string) => requests.get<ShiftsInfoByKeyModel>('GetShiftsInfoForRoleByKey/' + cono + '/' + key),
    shiftaltersenthistory: (cono: string, shiftalertid: string) => requests.get<SentShiftFromHistory[]>('/GetShiftAlertSentHistory/' + cono + '/' + shiftalertid),
    putopenshiftresponse: (response: OpenShiftResponse) => requests.post<string>('/PutOpenShiftResponse', response),
    viewshiftalertresponses: (cono: string, shiftalertid: string) => requests.get<ShiftAlertResponse[]>('/GetShiftResponses/' + cono + '/' + shiftalertid),
    getshiftalertinfobyid:(cono: string, shiftalertid: string) => requests.get<ShiftAlertInfoByID[]>('/GetShiftAlertInfoByID/' + cono + '/' + shiftalertid),   
    getshiftsetups: (cono: string) => requests.get<ShiftSetup[]>('/GetShiftsSetup/' + cono),
    getshiftsetupsforrole: (cono: string, roleid: number) => requests.get<ShiftSetup[]>('/GetShiftsSetupForRole/' + cono + "/" + roleid), 
    allshiftresponses: (cono: string) => requests.get<ShiftAlertResponse[]>('/GetShiftResponsesAllForOpenShifts/' + cono),
    // getisshiftstillavailable:(cono: string, shiftalertid: string) => requests.get<number>('/GetIsShiftAlertStillAvailable/' + cono + '/' + shiftalertid),
    putshiftalertstopresponses: (response: ShiftAlertStopResponses) => requests.post<void>('/PutShiftAlertStopResponses', response),
    getemailandtextusage: (cono: string, from: string, thru: string) => requests.get<EmailAndTextUsage[]>('/GetEmailAndTextUsage/' + cono + "/" + from + "/" + thru + "/"), 
    createkeysandsendalertforrole: (shift: SendShiftAlertForRoleModel) => requests.post<void>('/CreateKeysAndSendAlertForRole', shift),
    confirmshiftsnomessage: (shiftsjson : string) => requests.post<void>('/ConfirmShiftsNoMessage', shiftsjson),
    confirmshiftssinglemessage: (shiftsjson : string) => requests.post<void>('/ConfirmShiftsSingleMessage', shiftsjson),
    confirmshiftsmultiplemessages: (shiftsjson : string) => requests.post<void>('/ConfirmShiftsMultipleMessages', shiftsjson),
    putshiftsbytemplate:(shiftsjson: string) => requests.post<void>('/PutShiftsByTemplate',shiftsjson),
    getuserrecommendationsforshifts: (cono: string, from: string, thru: string) => requests.get<UserShiftRecommendation[]>('/GetUserRankingsForShifts/' + cono + "/" + from + "/" + thru + "/"),
    sendshiftalerttooneuser: (shift: ShiftAlertForSingleUser) => requests.post<void>('/SendShiftAlertToOneUser', shift), 
    removeusershiftresponse: (response: UserShiftResponse) => requests.post<void>('/RemoveUserShiftResponse', response),
    getopenshiftsfornewkey: (key: string) => requests.get<OpenShiftForNewKey[]>('GetOpenShiftsForNewKey/' + key),
    getopenshiftsinfobynewkey: (key: string) => requests.get<OpenShiftInfoForNewKey[]>('GetOpenShiftsInfoByNewKey/' + key),
}

const Users = {
    list: (fac: string) => requests.get<User[]>('/GetUserList/' + fac),
    inactiveuserlist: (fac: string) => requests.get<User[]>('/GetInactiveUserList/' + fac),
    create: (user: NewUserModel) => requests.post<void>('/CreateNewUser', user),
    isusernameavailable: (username: string) => requests.get<boolean>('/IsUsernameAvailable/' + username + '/'),
    isphonenumberavailable: (phone: string) => requests.get<number>('/IsPhoneNumberAvailable/' + phone + '/'),
    activate: (username: UsernameDTO) => requests.post<void>('/ActivateUser', username),
    inactivate: (username: UsernameDTO) => requests.post<void>('/InactivateUser', username),
    verify: (user: VerifyUser) => requests.post<void>('/VerifyUser', user),
    login: (user: UserLoginModel) => requests.post<AuthenticatedUser[]>('/Login', user),
    changepassword: (user: ChangePasswordModel) => requests.post<void>('/ChangeUserPassword', user),
    companylist: (username: string) => requests.get<CompanyModel[]>('/GetCompanies/' + username + '/'),
    companyroles: (cono: string) => requests.get<CompanyRole[]>('/GetCompanyRoles/' + cono),
    getcompanysecondaryroles: (cono: string) => requests.get<SecondaryRole[]>('/GetCompanySecondaryRoles/' + cono),
    companyroleswithlastsent: (cono: string) => requests.get<CompanyRoleWithLastSentModel[]>('/GetCompanyRolesWithLastSent/' + cono),
    usershiftresponses: (cono: string) => requests.get<UserShiftResponse[]>('/GetShiftResponsesAll/' + cono),    
    usershiftresponsesafterdate: (cono: string,afterdate: string) => requests.get<UserShiftResponse[]>('/GetShiftResponsesAfterDate/' + cono + '/' + afterdate + '/'),    
    usershiftresponsesafterdategrouped: (cono: string,afterdate: string) => requests.get<UserShiftsGrouped[]>('/GetShiftResponsesAfterDateGroupByShiftID/' + cono + '/' + afterdate + '/'), 
    getcompanyrolesbykey: (key: string) => requests.get<CompanyRoleModel[]>('/GetCompanyRolesByKey/' + key),
    registernewuser: (user: RegisterNewUserModel) => requests.post<void>('/RegisterNewUser', user),
    putuseracceptedtermsfromregistration: (user: RegisterNewUserModel) => requests.post<void>('/PutUserAcceptedTermsFromRegistration', user),
    sendconfirmationcode: (phone: UserConoAndPhoneModel) => requests.post<void>('/SendConfirmationCodeByPhone', phone),
    resetpassword: (user: ChangePasswordModel) => requests.post<AxiosResponse>('/ResetUserPassword', user),
    getcompanybykey: (key: string) => requests.get<CompanyModel>('/GetCompanyByKey/' + key + '/'),
    doesconfirmationkeymatch: (phone: string, key: string) => requests.get<boolean>('DoesConfirmationKeyMatch/' + phone + '/' + key + '/'),
    isuserabletoresetpassword: (username: string) => requests.get<boolean>('IsUserAbleToResetPassword/' + username  + '/'),
    isuserabletoresetpasswordbyphone: (phone: string) => requests.get<boolean>('IsUserAbleToResetPasswordByPhone/' + phone  + '/'),
    sendviewshiftlink: (user: UserConoAndPhone) => requests.post<boolean>('/SendViewShiftLink', user),
    getusershiftsbykey:(key: string) => requests.get<UserShiftsByKey[]>('/GetUserShiftsByKey/' + key),
    putuseronhold:(username: UsernameDTO) => requests.post<void>('/PutUserOnHold', username),
    putuseroffhold:(username: UsernameDTO) => requests.post<void>('/PutUserOffHold', username),
    putupdateuserdefaultcono: (info: UpdateUserDefaultCono) => requests.post<void>('/PutUpdateDefaultUserCono',info),
    getlookupuserbyphone: (phone: string) => requests.get<UserLookupByPhoneModel>('/GetLookupUserByPhone/' + phone + '/'),
    putaddusertocompany: (user: AddUserToCompanyDTOModel) => requests.post<void>('/PutAddUserToCompany', user),
    userlistfortemplateshift: (fac: string,roleid: number,templateid:number,dow: string,shiftid:number) => requests.get<User[]>('/GetUserListForTemplateShift/' + fac + '/' + roleid + '/' + templateid + '/' + dow + '/' + shiftid),
    usersenthistory: (cono: string,username: string) => requests.get<UserSentHistoryModel[]>('/GetUserSentHistory/' + cono + '/' + username + '/'),
    putcontactformsubmission: (user: ContactFormModel) => requests.post<void>('/ContactForm', user),
    putnewannouncement: (announcement: Announcement) => requests.post<void>('/PutNewAnnouncement', announcement),
    putexpireannouncement: (announcement: Announcement) => requests.post<void>('/PutExpireAnnouncement', announcement),
    getannouncements: (cono: string) => requests.get<Announcement[]>('/GetAnnouncements/' + cono + '/'),
    getannouncementsforadmin: (cono: string) => requests.get<Announcement[]>('/GetAnnouncementsForAdmin/' + cono + '/'),
    getannouncementsbykey: (key: string) => requests.get<Announcement[]>('/GetAnnouncementsByKey/' + key + '/'),
}

const Setups = {    
    createcompanyrole: (role: CompanyRoleModel) => requests.post<void>('/PutCompanyRoleNew', role),
    editcompanyrole: (role: CompanyRoleModel) => requests.post<void>('/PutCompanyRoleEdit', role),
    createcompanyshift: (shift: ShiftSetup) => requests.post<void>('/PutCompanyShiftNew', shift),
    editcompanyshift: (shift: ShiftSetup) => requests.post<void>('/PutCompanyShiftEdit', shift),
    companyrole: (cono: string, roleid: number) => requests.get<CompanyRoleModel>('/GetCompanyRole/' + cono + '/' + roleid),
    companyshiftsetup:(cono: string, roleid: number, shiftid: number) => requests.get<ShiftSetupModel>('/GetShiftSetup/' + cono + '/' + roleid + '/' + shiftid),
    getusersetup: (cono: string, username: string) => requests.get<NewUserModel>('/GetUserSetupForEdit/' + cono + '/' + username + '/'),
    getusersetuproles: (cono: string, username: string) => requests.get<UserRolesModel[]>('/GetUserSetupRolesForEdit/' + cono + '/' + username + '/'),
    getusersetupsecondaryrolesforedit: (cono: string, username: string) => requests.get<SecondaryRole[]>('/GetUserSetupSecondaryRolesForEdit/' + cono + '/' + username + '/'),
    edituser: (user: NewUserModel) => requests.post<void>('/PutUserEdit', user),        
    //edituserroles: (user: UserRolesModel) => requests.post<void>('/PutUserEditRoles', user),   
    getclaimedalertemails: (cono: string) => requests.get<ClaimedAlertEmailSetup[]>('/GetClaimedAlertEmails/' + cono),
    getclaimedalerttexts: (cono: string) => requests.get<ClaimedAlertTextSetup[]>('/GetClaimedAlertPhoneNumbers/' + cono),
    putclaimedalertemail: (email: ClaimedAlertEmailSetup) => requests.post<void>('/PutClaimedAlertEmail', email),
    removeclaimedalertemail: (email: ClaimedAlertEmailSetup) => requests.post<void>('/RemoveClaimedAlertEmail', email),
    putclaimedalerttext: (text: ClaimedAlertTextSetup) => requests.post<void>('/PutClaimedAlertText', text),
    removeclaimedalerttext: (text: ClaimedAlertTextSetup) => requests.post<void>('/RemoveClaimedAlertText', text),
    getshifttemplatesetups: (cono: string) => requests.get<ShiftTemplateSetupModel[]>('/GetShiftTemplateSetups/' + cono),
    getshifttemplate: (cono: string,roleid: number,templateid: number) => requests.get<ShiftTemplateModel[]>('/GetShiftTemplate/' + cono + '/'+ roleid + '/' + templateid),
    putshifttemplatesetupnew: (template: ShiftTemplateSetupModel) => requests.post<void>('/PutShiftTemplateSetup', template),
    putnewshiftintemplate: (template: ShiftInTemplate) => requests.post<void>('/PutNewShiftInTemplate', template),
    removeshiftfromtemplate: (template: RemoveShiftFromTemplateModel) => requests.post<void>('/RemoveShiftFromTemplate', template),
    putshifttemplateautoassignedusers: (users: AutoAssignUsersForTemplateShiftDTOModel) => requests.post<void>('/PutShiftTemplateAutoAssignedUsers', users),
    getautoassignusersfortemplate: (cono: string,roleid: number,templateid: number) => requests.get<AutoAssignedUserForTemplateModel[]>('/GetAutoAssignUsersForTemplate/' + cono + '/'+ roleid + '/' + templateid),
    getuserlistforapplyingtemplate: (cono: string,roleid: number) => requests.get<User[]>('/GetUserListForApplyingTemplate/' + cono + '/'+ roleid),
    getcompanyroleppds: (cono: string) => requests.get<CompanyRolePPD[]>('/GetCompanyRolePPDs/' + cono),
}

const Schedule = {
    putaddusershiftstoschedule: (shift: NewUserShiftInSchedule) => requests.post<void>('/PutUserShiftInSchedule', shift),
    getusersavailableforroleanddate: (fac: string,roleid: number, fromdate: string) => requests.get<User[]>('/GetUsersAvailableForRoleAndDate/' + fac + '/' + roleid + '/' + fromdate + '/'),
    putnewrotationsetup: (rot: RotationSetup) => requests.post<void>('/PutNewRotationSetup', rot),
    getrotationsetups: (fac: string) => requests.get<RotationSetup[]>('/GetRotationSetups/' + fac + '/'),
    getrotationsforasetup: (fac: string,rotationid: number) => requests.get<RotationDetailNew[]>('/GetRotationsForASetup/' + fac + '/' + rotationid + '/'),
    putnewuserinrotation: (rot: NewUserInRotation) =>requests.post<void>('/PutNewUserInRotation', rot),
    putnewshiftinrotation: (rot: NewShiftInRotation) =>requests.post<void>('/PutNewShiftInRotation', rot),
    removeshiftfromrotation: (rot: RemoveShiftFromRotationDTO) =>requests.post<void>('/RemoveShiftFromRotation', rot),    
    changeuserinrotation: (rot: ChangeUserInRotationDTO) =>requests.post<void>('/ChangeUserInRotation', rot),
    getunitsforcompany: (fac: string) => requests.get<UnitSetup[]>('/GetUnitsForCompany/' + fac + '/'),
    putrotationshiftsinschedule: (rot: RotationDetailNew[]) =>requests.post<void>('/PutRotationShiftsInSchedule', rot),
    getcurrentschedule: (fac: string,date: string) => requests.get<CurrentSchedule[]>('/GetCurrentSchedule/' + fac + '/' + date + '/'),
    getscheduletotalsbycompany: (username: string,sunday: string) => requests.get<ScheduleTotalsByCompany[]>('/GetScheduleTotalsByCompany/' + username + '/' + sunday + '/'),
    removeshiftfromschedule: (shift: RemoveShiftFromScheduleDTO) =>requests.post<void>('/RemoveShiftFromSchedule', shift),    
    puteditusershiftinschedule: (shift: EditUserShiftInScheduleDTO) =>requests.post<void>('/PutEditUserShiftInSchedule', shift),    
    putemptyuserinschedule: (shift: EmptyUsersInScheduleDTO) =>requests.post<void>('/PutEmptyUserInSchedule', shift),    
    putnewunit: (user: UnitSetupDTO) => requests.post<void>('/PutNewUnitSetup', user),    
    puteditunit: (user: UnitSetupDTO) => requests.post<void>('/PutEditUnitSetup', user),  
    putnewcompanyppd: (ppd: CompanyRolePPD) => requests.post<void>('/PutNewCompanyRolePPD', ppd),    
    puteditcompanyppd: (ppd: CompanyRolePPD) => requests.post<void>('/PutEditCompanyRolePPD', ppd),    
    getscheduleweeklytotals: (fac: string,date: string) => requests.get<ScheduledWeeklyTotals[]>('/GetScheduleWeeklyTotals/' + fac + '/' + date + '/'),
    removeuserfromweekschedule: (rot: RemoveUserFromWeekScheduleDTO) =>requests.post<void>('/RemoveUserFromWeekSchedule', rot),
    getscheduleshiftinfo: (fac: string,username: string, scheduleid: number) => requests.get<ScheduledShiftInfoModel>('/GetScheduleShiftInfo/' + fac + '/' + username + '/' + scheduleid + '/'),
    putbatchaddusershiftstoschedule: (shifts: BatchAddUsersToShiftsDTO) =>requests.post<void>('/PutBatchAddUserShiftsToSchedule', shifts),
    copyweeklyscheduletonewweek: (shifts: CopyWeeklyScheduleToNewWeekDTO) =>requests.post<void>('/CopyWeeklyScheduleToNewWeek', shifts),
    getshiftkeyhours: (cono: string) => requests.get<ShiftKeyHours[]>('/GetShiftKeyHours/' + cono),
    getshiftkeyhoursreporting: (username: string, sundaydate: string) => requests.get<ShiftKeyHours[]>('/GetShiftKeyHours/' + username + '/' + sundaydate + '/'),
    getshiftdifferentialsforrole: (cono: string,roleid: number, secondaryroleid: number) => requests.get<ShiftDifferentialRange[]>('/GetShiftDifferentialsForRole/' + cono + '/' + roleid + '/' + secondaryroleid),
    putshiftdifferential: (shiftdiff: ShiftDifferentialRange) => requests.post<void>('/PutShiftDifferential', shiftdiff),    
    putremoveshiftdifferential: (shiftdiff: ShiftDifferentialRange) => requests.post<void>('/PutRemoveShiftDifferential', shiftdiff),    
    putshiftdifferentialcopytodow: (shiftdiff: ShiftDifferentialCopyToDOW) => requests.post<void>('/PutShiftDifferentialCopyToDOW', shiftdiff),    
    getscheduleplannersetups: (cono: string) => requests.get<SchedulePlannerSetup[]>('/GetSchedulePlannerSetups/' + cono),
    getscheduleplannergroups: (cono: string,plannerid:number) => requests.get<SchedulePlannerGroup[]>('/GetSchedulePlannerGroups/' + cono + '/' + plannerid),
    getscheduleplannerusers: (cono: string,plannerid:number) => requests.get<SchedulePlannerUser[]>('/GetSchedulePlannerUsers/' + cono + '/' + plannerid),
    putscheduleplanner: (planner: SchedulePlannerSaveModel) => requests.post<void>('/PutSchedulePlanner', planner),    
    applyscheduleplannertoschedule: (planner: SchedulePlannerSaveModel) => requests.post<void>('/ApplySchedulePlannerToSchedule', planner),    
    putnewscheduleplannersetup: (planner: SchedulePlannerSaveModel) => requests.post<void>('/PutNewSchedulePlannerSetup', planner),    
}

const agent = {
    Shifts,
    Users,
    Setups,
    Schedule
}

export default agent;