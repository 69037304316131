import * as React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, MenuItem, Select, SelectChangeEvent, Stack, TextField, Typography } from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useEffect } from 'react';
import { useStore } from '../../stores/store';
import { ShiftDifferentialRange } from '../../models/ShiftDifferentials/ShiftDifferentialRange';
import { LoadingButton } from '@mui/lab';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';

function PaperComponent(props: PaperProps) {
    const nodeRef = React.useRef<HTMLDivElement>(null);
    return (
      <Draggable
        nodeRef={nodeRef as React.RefObject<HTMLDivElement>}
        handle="#draggable-dialog-title"
        cancel={'[class*="MuiDialogContent-root"]'}
      >
        <Paper {...props} ref={nodeRef} />
      </Draggable>
    );
  }

interface ShiftDiffProps {
    open: boolean;
    dow: string;
    roleid:number;
    secondaryroleid:number;
    onClose: (value: boolean) => void;
}

export default observer(function ShiftDiffComponent(props: ShiftDiffProps) {
    const { scheduleStore } = useStore();
    const { putShiftDifferential, loadingDetails } = scheduleStore;
    const { onClose, open } = props;
    const [dow, setDOW] = React.useState('');
    const [startTime, setStartTime] = React.useState<number | undefined>();
    const [endTime, setEndTime] = React.useState<number | undefined>();
    const [differential, setDifferential] = React.useState(0);
    const hourOptions = Array.from({ length: 25 }, (_, i) => i);
    const handleStartTimeChange = (event: SelectChangeEvent<number>) => {
        setStartTime(event.target.value as number);
    };

    const handleEndTimeChange = (event: SelectChangeEvent<number>) => {
        setEndTime(event.target.value as number);
    };

    const handleClose = (dorefresh: boolean) => {
        setStartTime(0);
        setEndTime(0);
        setDifferential(0);
        onClose(false);
    };

    useEffect(() => {
        setDOW(props.dow);
    }, []);


    const handleSave = () => {
        let cono = window.localStorage.getItem('cono');
        if (!cono || startTime == undefined || endTime == undefined) {
            console.log('something is null' + cono + ' ' + startTime + ' ' + endTime)
            return;
        }
        let s: ShiftDifferentialRange = {cono:cono, roleid:props.roleid, secondaryroleid:props.secondaryroleid,
                                         dow: props.dow, starttime: startTime.toString(), endtime: endTime.toString(), differential: differential};        
        console.log(s);
        putShiftDifferential(s).then(() =>{
            handleClose(false);
        });        
    };
    return (
        <>
        <Dialog
                open={open}
                onClose={handleClose}
                PaperComponent={PaperComponent}
                aria-labelledby="draggable-dialog-title"
            >
                <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">Add Shift Differential</DialogTitle>
                <DialogContent dividers={true}>
            <Typography sx={{ alignContent: 'center', textAlign: 'center', marginBottom: 2, fontSize: 18 }}>{props.dow}</Typography>
            <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <Stack direction={'row'}>
                    <div className="mb-4">
                        <Typography >Start Time</Typography>
                        <Select
                            value={startTime}
                            onChange={handleStartTimeChange}
                            sx={{ width: 95 }}
                            size='small'
                        >
                            {hourOptions.map(hour => (
                                <MenuItem key={hour} value={hour}>
                                    {hour.toString().padStart(2, '0')}:00
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div className="mb-4">
                    <Typography sx={{ marginLeft: 2, marginRight:2, marginTop:2, fontSize:24 }}>-</Typography>
                    </div>
                    <div className="mb-4">
                        <Typography>End Time</Typography>
                        <Select
                            value={endTime}
                            onChange={handleEndTimeChange}
                            sx={{ width: 95 }}
                            size='small'
                        >
                            {hourOptions.map(hour => (
                                <MenuItem key={hour} value={hour}>
                                    {hour.toString().padStart(2, '0')}:00
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                </Stack>
                <div className="mb-4">
                    <Stack sx={{alignContent:'center'}}>
                    <Typography sx={{ marginTop: 2, textAlign:'center' }}>Shift Differential ($)</Typography>
                    <TextField size='small' type='number'
                        // onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        //     handleUpdateCensus(0, Number(event.target.value));
                        // }}
                        value={differential}
                        inputProps={{
                            maxLength: 13,
                            step: ".01",
                            style: { fontSize: 14 },
                            min: 0
                        }}
                        sx={{
                            width: 120, alignSelf: 'center', alignContent: 'center',
                            alignItems: 'center'
                        }} id="outlined-basic"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setDifferential(Number.parseFloat(event.target.value));
                          }}>
                    </TextField>
                    </Stack>
                </div>
            </div>
            </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        color="primary"
                        autoFocus
                        sx={{ margin: 1, borderRadius: 5 }}
                        onClick={() => handleClose(false)}>
                        Cancel
                    </Button>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        loading={loadingDetails}
                        autoFocus
                        sx={{ margin: 1, borderRadius: 5 }}
                        onClick={() => handleSave()}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
})