import React, { useEffect, useState } from 'react';
import {
    Alert,
    Box,
    Button,
    Card,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
    Snackbar,
    Stack,
    styled,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { useStore } from '../../stores/store';
import { Announcement } from '../../models/Announcement';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { observer } from 'mobx-react-lite';

interface Facility {
    id: string;
    name: string;
}

interface FormData {
    announcement: string;
    expireDate: Date | null;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // borderWidth: 2,
        // borderStyle: 'solid',
        // borderColor: theme.palette.primary.main
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#ebebeb ',
        //backgroundColor: theme.palette.action.hover,
    },

}));

export default observer(function CreateNewAnnouncement() {
    const { userStore } = useStore();
    const { putNewAnnouncement, loading, loadAnnouncementsForAdmin, getAllAnnouncements, putExpireAnnouncement } = userStore;
    const [formData, setFormData] = useState<FormData>({
        announcement: '',
        expireDate: null,
    });
    const [isError, setIsError] = React.useState(false);
    const [showCreateAnnouncement, setShowCreateAnnouncement] = React.useState(false);
    const [openSuccess, setOpenSuccess] = React.useState(false);
    const handleCloseSnack = () => {
        setOpenSuccess(false);
    };

    useEffect(() => {
        document.title = 'Announcements';
        loadAnnouncementsForAdmin();
    }, []);

    const handleCreateAnnouncementClick = () => {
        setShowCreateAnnouncement(true);
    }
    const handleCloseDialog = () => {
        setShowCreateAnnouncement(false);
    };

    const handleClickExpire = (a: Announcement) => {
        putExpireAnnouncement(a).then(() => {
            loadAnnouncementsForAdmin();
        })
    }

    const handleAnnouncementChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            announcement: event.target.value,
        });
    };

    const handleDateChange = (newDate: Date | null) => {
        setFormData({
            ...formData,
            expireDate: newDate,
        });
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        console.log('Form submitted:', formData);
        const cono = window.localStorage.getItem('cono');
        if (!cono || formData.expireDate == null || formData.announcement == '') {
            setIsError(true);
            return
        }
        setIsError(false);
        let a: Announcement = { cono: cono, companyname: '', announcementid: -1, announcement: formData.announcement, expirationdate: formData.expireDate }
        putNewAnnouncement(a).then(() => {
            formData.announcement = '';
            formData.expireDate = null;
            setOpenSuccess(true);
        });

        // Add your form submission logic here
    };

    return (
        <>
            <Container component="main" maxWidth="sm" sx={{ paddingBottom: 10 }}>
                <Stack>
                    <Typography fontSize={30} textAlign={'center'} fontWeight={'bold'}>Current Announcements</Typography>
                    <Button
                        onClick={handleCreateAnnouncementClick}
                        sx={{ margin: 1 }}
                        variant='outlined'
                        className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                    >
                        <AddCircleIcon sx={{marginRight:1}}/>
                        Create New Announcement
                    </Button>
                    {getAllAnnouncements.length > 0 ?
                        <Table>
                            <TableHead>
                                <TableRow key={-1}>
                                    {/* <TableCell>ID</TableCell>
              <TableCell>Company</TableCell> */}
                                    <StyledTableCell><Typography fontWeight={'bold'}>Announcement</Typography></StyledTableCell>
                                    <StyledTableCell><Typography fontWeight={'bold'}>Expiration Date</Typography></StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {getAllAnnouncements.map((announcement) => (
                                    <TableRow key={announcement.announcementid}>
                                        {/* <TableCell>{announcement.announcementid}</TableCell>
                <TableCell>{announcement.cono}</TableCell> */}
                                        <StyledTableCell>{announcement.announcement}</StyledTableCell>
                                        <StyledTableCell>
                                            {new Date(announcement.expirationdate).toLocaleDateString()}
                                        </StyledTableCell>
                                        <StyledTableCell>
                                            <Button variant='outlined' color='error' onClick={() => handleClickExpire(announcement)}>Expire</Button>
                                        </StyledTableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table> :
                        <>No Announcements</>}
                </Stack>
                <Snackbar open={openSuccess} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                        Successfully Created Announcement
                    </Alert>
                </Snackbar>

                <Dialog open={showCreateAnnouncement} onClose={handleCloseDialog}>
                    <DialogTitle>
                        Create Announcement
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <Stack>
                            <TextField
                                label="Announcement"
                                sx={{margin:2}}
                                multiline
                                rows={4}
                                value={formData.announcement}
                                onChange={handleAnnouncementChange}
                                required
                            />

                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Expiration Date"
                                    value={formData.expireDate}
                                    sx={{margin:2}}
                                    onChange={handleDateChange}
                                    slotProps={{
                                        textField: {
                                            required: true,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            {isError ?
                                <Typography color='red'>Announcement and Expiration Date Is Required</Typography>
                                : <></>}
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            color="primary"
                            autoFocus onClick={() => setShowCreateAnnouncement(false)}
                            sx={{ margin: 1, borderRadius: 5 }}>
                            Close
                        </Button>
                        <LoadingButton
                            sx={{ margin: 1, borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            loading={loading}
                        >
                            Create Announcement
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </Container>
        </>
    );
})