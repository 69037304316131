import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button, Checkbox, Container, Box, Stack, Typography, CircularProgress, IconButton, Paper, Table, TableBody, TableContainer, TableHead } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Card from '@mui/material/Card';
import { Link, useParams } from 'react-router-dom';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import LoadingButton from '@mui/lab/LoadingButton';
import FormGroup from '@mui/material/FormGroup';
import { useStore } from '../../stores/store';
import { DragHandleRounded } from "@mui/icons-material";
import CompanyRoleModel from "../../models/CompanyRole";
import FormikFieldDateTimePicker from "../../components/FormikFieldDateTimePicker";
import { history } from '../../..';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default observer(function EditRole() {
    const { setupsStore } = useStore();
    const { loadCompanyRole, editRole, loading } = setupsStore;
    const cono = window.localStorage.getItem('cono');
    const { roleid } = useParams<{ roleid: string }>();

    const formik = useFormik({
        initialValues: editRole || new CompanyRoleModel(),
        enableReinitialize:true,
        onSubmit: values => {
            formik.setFieldValue('cono', cono);
            setupsStore.editCompanyRole(values).then(() => handleGoToRoleSetup());

        }
    });

    const handleGoToRoleSetup = () => {
        history.push("/rolesetup");
        history.go(0);
      }

    useEffect(() => {
        if (!roleid) {
            console.log('no id');
            return;
        }
        loadCompanyRole(Number.parseInt(roleid));

    }, []);


    const handleReset = () => {
        console.log('try to reset');
        formik.resetForm({
            values: { cono: cono || '', role: '', roleid: -1, ppdbudget:0,ppdbudgetmin:0,ppdbudgetmax:0, isSelected: false, isadministrationrole: false,secondaryroles:'' },
        });
    };

    if (loading || !editRole) return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <h1 style={{
                    textAlign: 'center', fontSize: '18',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit'
                }}>Loading Role</h1>
                <CircularProgress />
            </Stack>
        </div>
    )

    return (
        <Container component="main" maxWidth="sm" sx={{paddingBottom: 10}}>
            <Card sx={{ margin: 2, paddingLeft: 1, paddingRight: 1 }} raised>
                <Box
                    sx={{
                        marginTop: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                    <Stack direction="row" justifyContent="left" alignItems="center">
                        <Button component={Link} to="/rolesetup"><ArrowCircleLeftRoundedIcon fontSize="large" /></Button>
                        <Typography component="h1" variant="h5">
                            <span>Edit Role</span>
                        </Typography>
                    </Stack>
                    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                        <FormGroup>
                            <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, textAlign: 'left' }}>Role Name</InputLabel>
                            <TextField
                                name="role"
                                value={formik.values.role}
                                onChange={formik.handleChange} />
                        </FormGroup>
                        <Stack direction='row'>
                                <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 2, marginBottom: 2, textAlign: 'left' }}>Job Description(s)</InputLabel>
                                <IconButton aria-label="delete" >
                                    <AddCircleIcon color='warning' />
                                </IconButton>
                            </Stack>
                            <div>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 200 }} size='small' aria-label="simple table">
                                        <TableHead>
                                            {/* <TableRow>
                                            <TableCell>Role</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow> */}
                                        </TableHead>
                                        <TableBody>
                                            {/* {getUserEditRoles && getUserEditRoles.map((role) =>
                                                <TableRow key={role.roleid}>
                                                    <TableCell>{role.role}</TableCell>
                                                    <TableCell align='right'>
                                                        <IconButton aria-label="delete" onClick={() => handleClickRemoveRole(role)}>
                                                            <DeleteIcon color='error' />
                                                        </IconButton>
                                                    </TableCell>
                                                </TableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        <LoadingButton
                            sx={{ marginTop: 2, marginBottom: 2,borderRadius: 5 }}
                            variant="contained"
                            color="primary"
                            onClick={formik.submitForm}
                            loading={false}>
                            Save Changes
                        </LoadingButton>
                    </form>
                </Box>
            </Card>
        </Container>
    )
})