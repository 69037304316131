import React, { useState } from 'react';
import { 
  Box,
  Button,
  Paper,
  Typography,
  ThemeProvider,
  createTheme,
  styled
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

type ButtonValue = string | 'clear' | 'delete';

interface ButtonProps {
  value: ButtonValue;
  onClick: (value: ButtonValue) => void;
}

// Create a custom circular button component
const CircleButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: '64px',
  width: '64px',
  height: '64px',
  margin: theme.spacing(1),
  fontWeight: 'bold',
  fontSize: '1.25rem',
}));

// Custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    error: {
      main: '#d32f2f',
    },
    warning: {
      main: '#ed6c02',
    },
  },
});

const NumberButton: React.FC<ButtonProps> = ({ value, onClick }) => {
  const getButtonColor = (): 'primary' | 'error' | 'warning' => {
    switch (value) {
      case 'clear':
        return 'error';
      case 'delete':
        return 'warning';
      default:
        return 'primary';
    }
  };

  const getButtonVariant = (): 'contained' | 'outlined' => {
    return value === 'clear' || value === 'delete' ? 'contained' : 'outlined';
  };

  const getDisplayValue = () => {
    switch (value) {
      case 'delete':
        return <DeleteOutlineIcon />;
      case 'clear':
        return 'C';
      default:
        return value;
    }
  };

  return (
    <CircleButton
      variant={getButtonVariant()}
      color={getButtonColor()}
      onClick={() => onClick(value)}
    >
      {getDisplayValue()}
    </CircleButton>
  );
};

const TimeClock: React.FC = () => {
  const [display, setDisplay] = useState<string>('');

  const handleClick = (value: ButtonValue): void => {
    switch (value) {
      case 'clear':
        setDisplay('');
        break;
      case 'delete':
        setDisplay(prev => prev.slice(0, -1));
        break;
      default:
        setDisplay(prev => prev + value);
    }
  };

  const buttons: ButtonValue[] = [
    '1', '2', '3',
    '4', '5', '6',
    '7', '8', '9',
    'delete', '0', 'clear'
  ];

  return (
    <ThemeProvider theme={theme}>
      <Paper 
        elevation={3}
        sx={{
          width: 280,
          padding: 2,
          backgroundColor: '#f5f5f5',
        }}
      >
        <Paper
          elevation={1}
          sx={{
            mb: 2,
            p: 2,
            height: 48,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            backgroundColor: 'white',
          }}
        >
          <Typography variant="h4" component="div">
            {display || '0'}
          </Typography>
        </Paper>

        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 1,
            justifyItems: 'center',
          }}
        >
          {buttons.map((btn) => (
            <NumberButton
              key={btn}
              value={btn}
              onClick={handleClick}
            />
          ))}
        </Box>
      </Paper>
    </ThemeProvider>
  );
};

export default TimeClock;