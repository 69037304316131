import { makeAutoObservable, runInAction } from 'mobx';
import agent from '../api/agent';
import NewUserModel from '../models/NewUser';
import { User, UserFormValues, UserFormValuesModel } from '../models/User';
import UserLoginModel from '../models/UserLoginModel';
import { history } from '../..';
import { AuthenticatedUser } from '../models/AuthenticatedUser';
import ChangePasswordModel from '../models/ChangePasswordModel';
import { CompanyModel } from '../models/CompanyModel';
import CompanyRoleModel, { CompanyRole } from '../models/CompanyRole';
import { UserShiftResponse } from '../models/UserShiftResponse';
import RegisterNewUserModel from '../models/RegisterNewUserModel';
import { VerifyUser } from '../models/Setups/VerifiyUser';
import { UsernameDTO } from '../models/Setups/UsernameDTO';
import CompanyRoleWithLastSentModel, { CompanyRoleWithLastSent } from '../models/CompanyRoleWithLastSent';
import { format } from 'date-fns'
import { UserConoAndPhone } from '../models/UserConoAndPhone';
import { UserShiftsByKey } from '../models/UserShiftsByKey';
import { UpdateUserDefaultCono } from '../models/UpdateUserDefaultCono';
import { UserLookupByPhoneModel } from '../models/Setups/UserLookupByPhone';
import AddUserToCompanyDTOModel from '../models/Setups/AddUserToCompanyDTO';
import { HistoryOutlined } from '@mui/icons-material';
import UserSentHistoryModel, { UserSentHistory } from '../models/UserSentHistory';
import ContactFormModel from '../models/ContactForm';
import ResponsesList from '../views/responses/ResponsesList';
import { UserShiftsGrouped } from '../models/UserShiftsGrouped';
import { SecondaryRole } from '../models/Setups/SecondaryRole';
import { Announcement } from '../models/Announcement';


export default class UserStore {
    userRegistry = new Map<string, User>();
    userSentHistoryRegistry = new Map<string, UserSentHistory>();
    usersForTemplateShift = new Map<string, User>();
    inactiveUserRegistry = new Map<string, User>();
    userResponses = new Map<string, UserShiftResponse>();
    userResponsesGrouped = new Map<string, UserShiftsGrouped>();
    companyRoles = new Map<string, CompanyRole>();
    companySecondaryRoles = new Map<string, SecondaryRole>();
    companyRolesWithSent = new Map<string, CompanyRoleWithLastSentModel>();
    companyRolesByKey = new Map<number, CompanyRoleModel>();
    userShiftsByKey = new Map<string, UserShiftsByKey>();
    announcements = new Map<string, Announcement >();
    userFacilitiesForKey = new Map<string, string>(); //key is cono, value is company name
    companyInfo: CompanyModel | undefined = undefined;
    user: AuthenticatedUser | null = null;
    token: string | null = window.localStorage.getItem('sec');
    loading = false;
    loadingUsers = false;
    sendingConfirmationCode = false;
    checkUniqueUsername = false;
    checkUniquePhone = false;
    checkConfirmationCode = false;
    checkCanUserResetPassword = false; //check user if it exists and if they are able to have a proper log in for the system
    editUser: User | null = null;
    lookUpUserByPhone: UserLookupByPhoneModel | null = null;
    loadingLookup = false;
    // cono: string | null = window.localStorage.getItem('cono');
    companies = new Map<string, CompanyModel>();
    loadingCalendar = false;

    constructor() {
        makeAutoObservable(this);
    }

    get isLoggedIn() {
        console.log('are we logged in ' + !!this.user);
        console.log('are we logged in with local storage ' + window.localStorage.getItem('first'));
        return !!this.user || !!window.localStorage.getItem('user');
    }

    get getFirstLetterOfName() {
        //console.log('user ' + !!(this.user!.firstname.toString || 't'));
        let v = window.localStorage.getItem('first') || 'U';
        return v.toString().substring(0, 1);
    }
    get getFirstName() {
        let v = window.localStorage.getItem('first') || 'User';
        return v;
    }


    login = async (creds: UserFormValues, loc: string) => {
        ///this needs to eventually hit the server and actually log in
        console.log('logging in ' + creds.email);
        this.loading = true;

        // const u = new UserFormValuesModel(creds.email,creds.password);
        // runInAction(() => this.user = creds);
        // localStorage.setItem('user',creds.email);
        try {
            const u = new UserLoginModel();
            u.Email = creds.email.trim();
            u.Password = creds.password;
            u.Location = loc;
            const tempuser = await agent.Users.login(u);
            // user.forEach(temp => {
            //     console.log('set token ' + temp.securitystamp);
            //     runInAction(() => this.user = temp);
            //     // localStorage.setItem('user',u.username);
            //     // localStorage.setItem('sec',u.securitystamp);
            // })
            runInAction(() => this.user = tempuser[0]);
            window.localStorage.setItem('user', tempuser[0].username);
            window.localStorage.setItem('first', tempuser[0].firstname);
            window.localStorage.setItem('last', tempuser[0].lastname);
            window.localStorage.setItem('sec', tempuser[0].securitystamp);
            window.localStorage.setItem('cono', tempuser[0].defaultcono);
            window.localStorage.setItem('isregional', tempuser[0].isregional.toString());
            window.localStorage.setItem('enablescheduling', tempuser[0].enablescheduling.toString());
            //alert(JSON.stringify(this.user, null, 2));
            console.log('great job' + tempuser[0].defaultcono);
            this.loading = false;
            history.push("/home");
            history.go(0);
        }
        catch (error) {
            console.log('error login ' + error);
            this.loading = false;
            //throw error;
        }

    }

    logout = async () => {
        window.localStorage.clear();
        this.user = null;
    }

    IsUsernameAvailable = async (username: string) => {
        this.checkUniqueUsername = true;
        let IsAvailable = false;
        await agent.Users.isusernameavailable(username).then((response) => {
            if (response == true) {
                this.checkUniqueUsername = false;
                console.log('username is available');
                IsAvailable = true;
            } else {
                this.checkUniqueUsername = false;
                console.log('username is not available');
                IsAvailable = false;
            }
        });
        // console.log(unique);
        // this.checkUniqueUsername = false;
        // if(unique === 1){
        //     return true;
        // }else{
        //     return false;
        // }     
        return IsAvailable;
    }

    IsPhoneNumberAvailable = async (phone: string) => {
        this.checkUniquePhone = true;
        let IsAvailable = 0;
        await agent.Users.isphonenumberavailable(phone).then((response) => {
            if (response == 0) {
                this.checkUniquePhone = false;
                console.log('phone is new');
                IsAvailable = 0;
            } else if(response == 1){
                this.checkUniquePhone = false;
                console.log('phone number is already in the system but the users hasnt accepted terms');
                IsAvailable = 1;
            }
            else{ //response is 2, phone number exists and the user has accepted the terms
                this.checkUniquePhone = false;
                IsAvailable = 2;
            }
        });
        return IsAvailable;
    }

    DoesConfirmationKeyMatch = async (phone: string, key: string) => {
        this.checkConfirmationCode = true;
        let matches = false;
        await agent.Users.doesconfirmationkeymatch(phone, key).then((response) => {
            if (response == true) {
                this.checkConfirmationCode = false;
                console.log('key matches');
                matches = true;
            } else {
                this.checkConfirmationCode = false;
                console.log('key does not match');
                matches = false;
            }
        });
        this.checkConfirmationCode = false;
        return matches;
    }

    IsUserAbleToResetPassword = async (phone: string) => {
        this.checkCanUserResetPassword = true;
        this.sendingConfirmationCode = true;
        let canreset = false;
        await agent.Users.isuserabletoresetpasswordbyphone(phone).then((response) => {
            if (response == true) {
                this.checkCanUserResetPassword = false;
                this.sendingConfirmationCode = false;
                console.log('key matches');
                canreset = true;
            } else {
                this.checkCanUserResetPassword = false;
                this.sendingConfirmationCode = false;
                console.log('key does not match');
                canreset = false;
            }
        });
        this.checkCanUserResetPassword = false;
        this.sendingConfirmationCode = false;
        return canreset;
    }

    loadUsers = async () => {
        console.log('getting users ');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingUsers = true;
        this.userRegistry.clear();
        try {
            const users = await agent.Users.list(cono);
            users.forEach(u => {
                this.setUser(u);
            })

            // const responses = await agent.Users.usershiftresponses(cono);
            // responses.forEach(r =>{
            //     this.setUserResponse(r);
            // })
            this.loadingUsers = false;
        } catch (error) {
            console.log('yeah here ' + error);
            this.loadingUsers = false;
        }
    }

    loadUserSentHistory = async (username: string) => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        try {
            const history = await agent.Users.usersenthistory(cono, username);
            history.forEach(u => {
                this.setUserSentHistory(u);
            })
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
        this.loading = false;
        return history;
    }
    private setUserSentHistory = (history: UserSentHistoryModel) => {
        this.userSentHistoryRegistry.set(history.datesent.toString(), history);
    }
    get getUserSentHistory() {
        return Array.from(this.userSentHistoryRegistry.values());
    }

    loadInactiveUsers = async () => {
        console.log('getting inactive users ');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        this.inactiveUserRegistry.clear();
        try {
            const users = await agent.Users.inactiveuserlist(cono);
            users.forEach(u => {
                this.setInactiveUser(u);
            })
            this.loading = false;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    private setInactiveUser = (user: User) => {
        this.inactiveUserRegistry.set(user.username.toString(), user);
    }
    get getInactiveUsers() {
        return Array.from(this.inactiveUserRegistry.values());
    }

    loadResponses = async (afterdate: Date) => {
        console.log('getting responses ');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let datestring = format(new Date(afterdate), 'MMddyyyy')
        this.loading = true;

        this.userResponses.clear();
        try {
            const responses = await agent.Users.usershiftresponsesafterdate(cono, datestring);
            responses.forEach(r => {
                this.setUserResponse(r);
            })
            this.loading = false;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }

        console.log('loading responses ' + this.userResponses.size);
    }


    loadResponsesGrouped = async (afterdate: Date) => {
        console.log('getting responses ');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let datestring = format(new Date(afterdate), 'MMddyyyy')
        this.loading = true;

        this.userResponsesGrouped.clear();
        try {
            const responses = await agent.Users.usershiftresponsesafterdategrouped(cono, datestring);
            console.log('got responses ' + responses.length);
            responses.forEach(r => {
                this.setUserResponseGrouped(r);
            })
            this.loading = false;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }

        console.log('loading grouped responses ' + this.userResponsesGrouped.size);
    }
    private setUserResponseGrouped = (response: UserShiftsGrouped) => {
        this.userResponsesGrouped.set(response.ShiftId.toString() + response.ShiftDate.toString() + response.RoleId.toString() + response.ShiftStartTime.toString() + response.ShiftEndTime.toString(), response);
    }
    get getUserResponsesGrouped() {
        return Array.from(this.userResponsesGrouped.values());
    }
    private getIsOvernightShift(endsnext: boolean, start: Date, end: Date) {
        if (endsnext) {
            return true;
        }
        else {            
            if (new Date(start).getHours() > new Date(end).getHours()) {
                console.log(start);
                return true;
            }
            else {
                return false;
            }
        }
    }
    get getUserResponsesForCalendarViewGrouped() {
        this.loadingCalendar = true;
        console.log('grouped ' + this.userResponsesGrouped.size);
        const newArray = Array.from(this.userResponsesGrouped.values()).map(item => ({
            event_id: item.ShiftId,
            title: item.ShiftDescr,
            start: new Date(new Date(item.ShiftDate).setHours(0, 0, 0, 0)),
            end: new Date(new Date(item.ShiftDate).setHours(0, 0, 0, 0)),
            disabled: false,
            admin: 1,
            color: this.getSchedulerColorByRoleid(item.RoleId),
            starttime: new Date(item.ShiftStartTime),
            endtime: new Date(item.ShiftEndTime),
            roleid: item.RoleId,
            usernames: 'ok user',
            ShiftDescr: '',
            shiftendsnextday: item.ShiftEndsNextDay,
            allDay: this.getIsOvernightShift(item.ShiftEndsNextDay,new Date(item.ShiftStartTime), new Date(item.ShiftEndTime)),
            Responses: item.Responses,
        }))
        newArray.forEach(d => {
            const username = d.Responses.map(i => i.name.trim());                        
            d.usernames = username.join(', ');
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(), d.starttime.getMinutes(), 0, 0));
            if (d.shiftendsnextday) {
                let newdate = new Date(d.end);
                newdate.setDate(newdate.getDate() + 1);
                d.end = new Date(new Date(newdate).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));  
                d.title = username.join(', ');              
                d.allDay = true;
            }
            else {
                d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
            }
            d.color = this.adjustColorByTime(this.getSchedulerColorByRoleid(d.roleid), d.start.getHours());
        })
        this.loadingCalendar = false;

        console.log('new array ' + newArray.length);
        return newArray;
    }
    private adjustColorByTime(hexColor: string, hour: number) {
        // Remove the '#' symbol if present
        hexColor = hexColor.replace('#', '');

        // Convert the hex color to RGB values
        let r = parseInt(hexColor.substring(0, 2), 16);
        let g = parseInt(hexColor.substring(2, 4), 16);
        let b = parseInt(hexColor.substring(4, 6), 16);

        // Calculate the adjustment factor based on the hour
        const adjustmentFactor = Math.log(Math.abs(hour - 12) + 1) / Math.log(13) * (hour < 12 ? -1 : 1);

        // Adjust the RGB values based on the adjustment factor
        r = Math.round(r * (1 + adjustmentFactor * .3));
        g = Math.round(g * (1 + adjustmentFactor * .3));
        b = Math.round(b * (1 + adjustmentFactor * .3));

        // Clamp the RGB values to the valid range (0-255)
        r = Math.max(0, Math.min(255, r));
        g = Math.max(0, Math.min(255, g));
        b = Math.max(0, Math.min(255, b));

        // Convert the adjusted RGB values back to a hex color
        const adjustedHexColor =
            '#' +
            ((r << 16) + (g << 8) + b).toString(16).padStart(6, '0').toUpperCase();

        return adjustedHexColor;
    }
    private setUser = (user: User) => {
        user.isSelected = true;
        this.userRegistry.set(user.username.toString(), user);
    }
    private setUserResponse = (response: UserShiftResponse) => {
        this.userResponses.set(response.Row.toString(), response);
    }

    public setEditUser = (user: User | null) => {
        this.editUser = user;
    }

    get getAllUsers() {
        return Array.from(this.userRegistry.values());
    }
    get getUserResponses() {
        return Array.from(this.userResponses.values());
    }

    setUserSelected = async (user: User) => {
        if (!this.userRegistry || !user) {
            return;
        }
        let r = this.userRegistry.get(user.username.toString());
        if (r != undefined) {
            r.isSelected = true;
            this.userRegistry.set(r.username.toString(), r);
        }
    }
    setUserUnSelected = async (user: User) => {
        if (!this.userRegistry || !user) {
            return;
        }
        let r = this.userRegistry.get(user.username.toString());
        if (r != undefined) {
            r.isSelected = false;
            this.userRegistry.set(r.username.toString(), r);
        }
    }

    get getUserResponsesForCalendarView() {
        this.loadingCalendar = true;
        const newArray = Array.from(this.userResponses.values()).map(item => ({
            event_id: item.Row,
            title: item.name + ' ' + item.descr,
            start: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            end: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            disabled: false, admin: 1, color: this.getSchedulerColorByRoleid(item.roleid),
            starttime: new Date(item.shiftstarttime),
            endtime: new Date(item.shiftendtime),
            roleid: item.roleid,
            username: item.username,
            shiftendsnextday: item.shiftendsnextday,
        }))
        newArray.forEach(d => {
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(), d.starttime.getMinutes(), 0, 0));
            if (d.shiftendsnextday) {
                let newdate = new Date(d.end);
                newdate.setDate(newdate.getDate() + 1);
                d.end = new Date(new Date(newdate).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
            }
            else {
                d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
            }
        })
        this.loadingCalendar = false;
        return newArray;
    }
    private getSchedulerColorByRoleid = (roleid: number) => {
        if (roleid === 0) {
            return '#D18C19';
        }
        if (roleid === 1) {
            return '#1976D2';
        }
        else if (roleid === 2) {
            return '#3DD119';
        }
        else if (roleid === 3) {
            return '#D61AB0';
        }
        else if (roleid === 4) {
            return '#4E7EAD';
        }
        else {
            return '#1976d2';
        }
    }

    loadUsersForTemplateShift = async (roleid: number, templateid: number, dow: string, shiftid: number) => {
        console.log('getting users for template shift ');
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loadingLookup = true;
        this.usersForTemplateShift.clear();
        try {
            const users = await agent.Users.userlistfortemplateshift(cono, roleid, templateid, dow, shiftid);
            users.forEach(u => {
                this.setUserForTemplateShift(u);
            })
            this.loadingLookup = false;
        } catch (error) {
            console.log(error);
            this.loadingLookup = false;
        }
    }
    get getUsersForTemplateShift() {
        return Array.from(this.usersForTemplateShift.values());
    }
    private setUserForTemplateShift = (user: User) => {
        this.usersForTemplateShift.set(user.username.toString(), user);
    }

    loadUserShiftsByKey = async (key: string) => {
        console.log('getting user shifts by key');
        this.userShiftsByKey.clear();
        this.loading = true;
        try {
            const shifts = await agent.Users.getusershiftsbykey(key);
            shifts.forEach(r => {
                this.setUserShiftByKey(r);
            })
        } catch (error) {
            this.loading = false;
            console.log(error);
        }

        try {
            this.userShiftsByKey.forEach(r => {
                this.setUniqueFacilityForUser(r);
            })
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }

        this.loading = false;
    }
    private setUniqueFacilityForUser = (shift: UserShiftsByKey) => {
        console.log('add fac ' + shift.companyname);
        this.userFacilitiesForKey.set(shift.cono, shift.companyname);
    }

    private setUserShiftByKey = (shift: UserShiftsByKey) => {
        this.userShiftsByKey.set(shift.shiftalertid, shift);
    }
    get getUsersShiftsByKey() {
        return Array.from(this.userShiftsByKey.values());
    }
    get getUsersUniqueFacilities() {
        return Array.from(this.userFacilitiesForKey.values());
    }
    private formatTime = (date: Date): string => {
        const hours = new Date(date).getHours();
        const minutes = new Date(date).getMinutes();
        const ampm = hours >= 12 ? 'p' : 'a';
        
        const formattedHours = hours % 12 || 12;
        const formattedMinutes = minutes > 0 ? `:${minutes.toString().padStart(2, '0')}` : '';
  
        return `${formattedHours}${formattedMinutes}${ampm}`;
      };
    get getUsersShiftsByKeyForSchedulerView() {
        const newArray = Array.from(this.userShiftsByKey.values()).filter(s => s.shiftstarttime != s.shiftendtime).map(item => ({
            event_id: item.shiftalertid, 
            title: this.formatTime(item.shiftstarttime) + '-' + this.formatTime(item.shiftendtime) + ' ' + item.role  + ' - ' + item.shiftdescr,
            // start: new Date(new Date(item.shiftdate).getFullYear(),new Date(item.shiftdate).getMonth(),            
            // new Date(item.shiftdate).getDay(),new Date(item.shiftstarttime).getHours(),new Date(item.shiftstarttime).getMinutes()),
            start: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            // end: new Date(new Date(item.shiftdate).getFullYear(),new Date(item.shiftdate).getMonth(),
            // new Date(item.shiftdate).getDay(),new Date(item.shiftendtime).getHours(),new Date(item.shiftendtime).getMinutes()),
            end: new Date(new Date(item.shiftdate).setHours(0, 0, 0, 0)),
            disabled: false, admin: 1, 
            color: this.getSchedulerColorByStatus(item.status),
            starttime: new Date(item.shiftstarttime),
            endtime: new Date(item.shiftendtime),
            status: item.status,
            shiftid: item.shiftid,
        }))
        newArray.forEach(d => {
            d.start = new Date(new Date(d.start).setHours(d.starttime.getHours(), d.starttime.getMinutes(), 0, 0));
            d.end = new Date(new Date(d.end).setHours(d.endtime.getHours(), d.endtime.getMinutes(), 0, 0));
        })
        // console.log('there are ' + newArray.length);
        return newArray;
    }

    private getSchedulerColorByStatus = (status: number) => {
        if (status === 1) {
            return '#ffb74d';
        }
        else if (status === 2) {
            return '#e6e6e6';
        }
        else if (status === 4) {
            return '#3DD119';
        }
        else {
            return '#1976d2';
        }
    }

    getUserLookUpByPhone = async (phone: string) => {
        this.loadingLookup = true;
        try {
            let user = await agent.Users.getlookupuserbyphone(phone);
            this.lookUpUserByPhone = user;
            this.loadingLookup = false;
            return user;
        }
        catch (error) {
            this.loadingLookup = false;
            return null;
        }
    }

    resetUserLookUpByPhone = () => {
        this.lookUpUserByPhone = null;
    }

    loadCompanyRolesByKey = async (key: string) => {
        const temp = await agent.Users.getcompanyrolesbykey(key);

        temp.forEach(r => {
            this.setCompanyRoleByKey(r);
        })
    };
    private setCompanyRoleByKey = (role: CompanyRoleModel) => {
        this.companyRolesByKey.set(role.roleid, role);
    }
    get getCompanyRolesByKey() {
        return Array.from(this.companyRolesByKey.values());
    }

    loadCompanySecondaryRoles = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        const temp = await agent.Users.getcompanysecondaryroles(cono);

        temp.forEach(r => {
            this.setCompanySecondaryRole(r);
        })
    };
    private setCompanySecondaryRole = (role: SecondaryRole) => {
        this.companySecondaryRoles.set(role.roleid.toString() + role.secondaryroleid.toString(), role);
    }
    get getCompanySecondaryRoles() {
        return Array.from(this.companySecondaryRoles.values());
    }

    getCompanyRoleForEdit = async (id: number) => {
        if (!this.companyRoles) {
            await this.loadCompanyRoles();
        }
        return this.companyRoles.get(id.toString());
    }

    loadCompanyRoles = async () => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
            this.loading = true;
        try {
            const roles = await agent.Users.companyroles(cono);
            roles.forEach(r => {
                this.setRole(r);
            })
                this.loading = false;
        } catch (error) {
            console.log('error getting roles' + error);
                this.loading = false;        
        }
    }
    private setRole = (role: CompanyRole) => {
        role.isSelected = true;
        this.companyRoles.set(role.roleid.toString(), role);
    }
    get getCompanyRoles() {
        return Array.from(this.companyRoles.values());
    }

    setCompanyRoleToDefault = async () => {
        this.companyRoles.forEach(r => {
            r.isSelected = true;
        })
    }

    setCompanyRoleSelected = async (role: CompanyRole) => {
        if (!this.companyRoles || !role) {
            return;
        }
        let r = this.companyRoles.get(role.roleid.toString());
        if (r != undefined) {
            r.isSelected = true;
            this.companyRoles.set(r.roleid.toString(), r);
        }
    }
    setCompanyRoleUnSelected = async (role: CompanyRole) => {
        if (!this.companyRoles || !role) {
            return;
        }
        let r = this.companyRoles.get(role.roleid.toString());
        if (r != undefined) {
            r.isSelected = false;
            this.companyRoles.set(r.roleid.toString(), r);
        }
    }

    loadCompanyRolesWithLastSent = async () => {
        this.loading = true;
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        try {
            this.companyRolesWithSent.clear();
            const roles = await agent.Users.companyroleswithlastsent(cono);
            roles.forEach(r => {
                this.setRoleWithSent(r);
            })
            this.loading = false;
        } catch (error) {
            console.log('error getting roles' + error);
            this.loading = false;
        }
    }
    private setRoleWithSent = (role: CompanyRoleWithLastSent) => {
        this.companyRolesWithSent.set(role.roleid.toString(), role);
    }

    get getCompanyRolesWithLastSent() {
        return Array.from(this.companyRolesWithSent.values());
    }

    loadCompanies = async () => {
        const u = window.localStorage.getItem('user');
        if (!u) {
            return
        }
        try {
            this.loading = true;
            const comps = await agent.Users.companylist(u);
            comps.forEach(c => {
                this.companies.set(c.cono, c);
            })
            this.loading = false;
            // alert(JSON.stringify(this.companies, null, 2));
        }
        catch (error) {
            this.loading = false;
            console.log(error);
        }
    }
    get GetCompanies() {
        return Array.from(this.companies.values());
    }

    createNewUser = async (user: NewUserModel) => {
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        user.cono = cono;
        console.log('try to create new user');
        this.loading = true;
        try {
            await agent.Users.create(user);
            this.loading = false;
        }
        catch (error) {
            console.log('Error creating new user ' + error);
            this.loading = false;
        }
    }
    loadAnnouncements = async (cono:string) => {
        this.loading = true;
        this.announcements.clear();
        try {
            const anns = await agent.Users.getannouncements(cono);
            anns.forEach(u => {
                this.setAnnouncement(u);
            })
            this.loading = false;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    loadAnnouncementsByKey = async (key:string) => {
        this.loading = true;
        this.announcements.clear();
        try {
            const anns = await agent.Users.getannouncementsbykey(key);
            anns.forEach(u => {
                this.setAnnouncement(u);
            })
            this.loading = false;
            console.log('announcments found ' + this.announcements.size);
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    loadAnnouncementsForAdmin = async () => {
        console.log('getting announcements');
        const cono = window.localStorage.getItem('cono');
        if (!cono) {
            return
        }
        this.loading = true;
        this.announcements.clear();
        try {
            const anns = await agent.Users.getannouncementsforadmin(cono);
            console.log('getting announcements found ' + anns.length);
            anns.forEach(u => {
                this.setAnnouncement(u);
            })
            this.loading = false;
        } catch (error) {
            console.log(error);
            this.loading = false;
        }
    }
    private setAnnouncement = (a: Announcement) => {
        this.announcements.set(a.announcementid.toString(), a);
    }
    get getAllAnnouncements() {
        return Array.from(this.announcements.values());
    }
    get doAnnouncementsExists(){
        if(this.announcements.size > 0){
            return true;
        }
        else{
            return false;
        }
    }
    putNewAnnouncement = async (ann: Announcement) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Users.putnewannouncement(ann);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }
    putExpireAnnouncement = async (ann: Announcement) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Users.putexpireannouncement(ann);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    putAddUserToCompany = async (user: AddUserToCompanyDTOModel) => {
        this.loadingLookup = true;
        let success = false;
        try {
            await agent.Users.putaddusertocompany(user);
            success = true;
            this.loadingLookup = false;
            return success;
        }
        catch (error) {
            this.loadingLookup = false;
            return success;
        }
    }

    putContactFormSubmission = async (user: ContactFormModel) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Users.putcontactformsubmission(user);
            success = true;
            this.loading = false;
            return success;
        }
        catch (error) {
            this.loading = false;
            return success;
        }
    }

    sendviewshiftlink = async (user: UserConoAndPhone) => {
        this.loading = true;
        let success = false;
        try {
            await agent.Users.sendviewshiftlink(user).then((response) => {
                if (response == true) {
                    success = true;
                } else {
                    success = false;
                }
            });
            this.loading = false;
        }
        catch (error) {
            console.log('error sending view to phone ' + error);
            this.loading = false;
            success = false;
        }
        console.log('phone link result ' + success);
        return success;
    }

    registerNewUser = async (user: RegisterNewUserModel) => {
        console.log('try to register new user');
        this.loading = true;
        try {
            await agent.Users.registernewuser(user);
            this.loading = false;
        }
        catch (error) {
            console.log('Error register new user ' + error);
            this.loading = false;
        }
    }
    putUserAcceptedTermsFromRegistration = async (user: RegisterNewUserModel) => {
        this.loading = true;
        try {
            await agent.Users.putuseracceptedtermsfromregistration(user);
            this.loading = false;
        }
        catch (error) {
            this.loading = false;
        }
    }

    putupdateuserdefaultcono = async (info: UpdateUserDefaultCono) => {
        console.log('try to change default cono');
        try {
            await agent.Users.putupdateuserdefaultcono(info);
        } catch (error) {

        }

    }

    changeUserPassword = async (user: ChangePasswordModel) => {
        console.log('try to change password');
        this.loading = true;
        try {
            await agent.Users.changepassword(user);
        }
        catch (error) {
            console.log('error changing password ' + error);
            this.loading = false;
        }
        this.loading = false;
    }

    activateUser = async (usernm: string) => {
        console.log('try to activate ' + usernm);
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        try {
            let u: UsernameDTO = { username: usernm, cono: cono }
            await agent.Users.activate(u);
            this.loading = false;
        }
        catch (error) {
            console.log('error activate user ' + error);
            this.loading = false;
        }
    }

    inactivateUser = async (usernm: string) => {
        console.log('try to inactivate ' + usernm);
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        try {
            let u: UsernameDTO = { username: usernm, cono: cono }
            await agent.Users.inactivate(u);
            this.loading = false;
        }
        catch (error) {
            console.log('error inactivating user ' + error);
            this.loading = false;
        }
    }

    putUserOnHold = async (usernm: string) => {
        console.log('try to put on hold ' + usernm);
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        try {
            let u: UsernameDTO = { username: usernm, cono: cono }
            await agent.Users.putuseronhold(u);
            this.loading = false;
        }
        catch (error) {
            console.log('error inactivating user ' + error);
            this.loading = false;
        }
    }

    putUserOffHold = async (usernm: string) => {
        console.log('try to take off hold ' + usernm);
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        this.loading = true;
        try {
            let u: UsernameDTO = { username: usernm, cono: cono }
            await agent.Users.putuseroffhold(u);
            this.loading = false;
        }
        catch (error) {
            console.log('error inactivating user ' + error);
            this.loading = false;
        }
    }

    sendconfirmationcode = async (phone: string) => {
        console.log('send confirmation code for forgot pass ' + phone);
        this.sendingConfirmationCode = true;
        let u: UserConoAndPhone = { phone: phone, secret: '' }
        try {
            await agent.Users.sendconfirmationcode(u);
            this.sendingConfirmationCode = false;
        }
        catch (error) {
            console.log('error sending confirm code ' + error);
            this.sendingConfirmationCode = false;
        }

        this.sendingConfirmationCode = false;
    }

    resetUserPassword = async (user: ChangePasswordModel) => {
        this.loading = true;
        try {
            await agent.Users.resetpassword(user).then((response) => {
                console.log('returning axios ' + response.status);
                return response.status;
            })
            this.loading = false;
        }
        catch (error) {
            console.log('error reset password ' + error);
            this.loading = false;
        }
    }

    verifyUser = async (user: VerifyUser) => {
        console.log('try to verify ' + user.username);
        this.loading = true;
        try {
            await agent.Users.verify(user);
            this.loading = false;
        }
        catch (error) {
            console.log('error inactivating user ' + error);
            this.loading = false;
        }
    }

    getCompanyInfoByKey = async (key: string) => {
        try {
            console.log('trying to get info by key ');
            this.loading = true;
            const info = await agent.Users.getcompanybykey(key);
            runInAction(() => {
                this.companyInfo = info;
                console.log('got fac ' + this.companyInfo.companyname);
            })
            this.loading = false;
            return info;
        } catch (error) {
            console.log('its messed up ' + error);
            this.loading = false;
        }
    }
}