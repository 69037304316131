import { object, string, number, boolean } from "yup";
import { CompanyRole } from "./CompanyRole";
import { SecondaryRole } from "./Setups/SecondaryRole";
import UserRoles from "./UserRoles";

export const NewUserModelSchema = object({
    cono: string(),
    firstname: string().required(),
    lastname: string().required(),
    credentials: string(),
    email: string(),
    optinemail: boolean(),
    phone: string(),
    optintext: boolean(),
    hiredate: string(),
    employeeid:string(),
    username:string(),
    isagency:boolean(),
});

export interface INewUser{
    cono: string;
    firstname: string;
    lastname: string;
    credentials: string;
    email: string;
    optinemail: boolean;
    phone: string;
    optintext: boolean;
    roleid: number;
    hiredate: string;
    roles: UserRoles[];
    employeeid:string;
    username:string;
    isagency:boolean;    
    secondaryroles: SecondaryRole[];
}

class NewUserModel implements INewUser{
    cono: string = "";
    firstname: string = "";
    lastname: string = "";
    credentials: string = "";
    email: string = "";
    optinemail: boolean = false;
    phone: string = "";
    optintext: boolean = false;
    roleid: number = 0;
    hiredate: string = "";
    roles: UserRoles[] = [];
    employeeid: string = "";
    username:string = "";
    isagency: boolean = false;
    secondaryroles: SecondaryRole[] = [];
}

export default NewUserModel;