import React, { useEffect } from 'react';
import { observer } from "mobx-react-lite";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, LinearProgress, List, ListItem, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { useStore } from '../../../stores/store';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { FormikConsumer, useFormik } from 'formik';
import { format } from 'date-fns';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { NewShiftInRotation } from '../../../models/Schedule/NewShiftInRotation';
import { LoadingButton } from '@mui/lab';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from "yup";
import { User } from '../../../models/User';
import { BatchAddUsersToShiftsDTO } from '../../../models/Schedule/BatchAddUsersToShiftsDTO';
import { SchedulePlannerUser } from '../../../models/Schedule/Planner/SchedulePlannerUser';

interface SelectRoleDialogProps {
    rolename: string;
    open: boolean;
    groupid: number;
    alreadyaddedusers:SchedulePlannerUser[];
    onClose: (selected: string[]) => void;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 350,
        },
    },
};

interface FormValues {
    selectedunitobject: string,
    startTime: Date,
    endTime: Date,
    assignedUsers: string[]
}

export default observer(function SchedulePlannerAddUsersToGroup(props: SelectRoleDialogProps) {
    const { scheduleStore, userStore } = useStore();
    const { loadUsers, getAllUsers } = userStore;
    const { onClose, open, rolename } = props;
    const [loadingEdit, setLoadingEdit] = React.useState(false);


    const [usersSelectedError, setUsersSelectedError] = React.useState(false);
    useEffect(() => {
        loadUsers();
    }, []);

    function excludeRoles(user: User) {
        let result: boolean;
        result = false;
        const values = user.Roles.split(',');
        values.forEach((v: string) => {
            if (v.trim() === rolename) {
                result = true;
            }
        })
        return result;
    }
    function excludeUsersThatAlreadyExistInGroup(user:User){
        let result = true;
        props.alreadyaddedusers.forEach((u) =>{
            if(u.groupid === props.groupid && u.username === user.username){
                result = false;
            }
        })
        return result;
    }    

    const handleClose = () => {
        scheduleStore.clearShiftInfo();
        formik.resetForm();
        onClose([]);
    };

    function areDatesEqual(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        const utcDate1 = Date.UTC(
            date1.getFullYear(),
            date1.getMonth(),
            date1.getDate(),
            date1.getHours(),
            date1.getMinutes(),
            date1.getSeconds()
        );
        const utcDate2 = Date.UTC(
            date2.getFullYear(),
            date2.getMonth(),
            date2.getDate(),
            date2.getHours(),
            date2.getMinutes(),
            date2.getSeconds()
        );

        // Compare the UTC timestamps
        return utcDate1 === utcDate2;
    }

    function doesShiftEndNextDay(date1: Date, date2: Date): boolean {
        // Convert both dates to the same timezone (e.g., UTC)
        if (date2.getHours() < date1.getHours()) {
            return true;
        }
        else {
            return false;
        }
    }
    const formik = useFormik<FormValues>({
        initialValues: {
            selectedunitobject: '',
            startTime: new Date(),
            endTime: new Date(),
            assignedUsers: [],
        },
        // validationSchema: ValidSchema,
        // validateOnBlur: true,
        onSubmit: (values) => {
            let co = window.localStorage.getItem('cono');
            if (!co) {
                return;
            }
            let haserrors = false;
            if (formik.values.assignedUsers.length <= 0) {
                setUsersSelectedError(true);
                haserrors = true;
            }

            if (haserrors) {
                return;
            }

            onClose(formik.values.assignedUsers);
            formik.resetForm();
        }
    });
    const renderUsernamesValue = (selected: string[]) => {
        const usernames = selected.map(item => {
            const user: User = JSON.parse(item);
            return user.username;
        });
    }

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth='md'>
                <DialogTitle textAlign={'center'}>Add User(s) to Group</DialogTitle>
                {loadingEdit ?
                    <>
                        <DialogContent dividers={true}>
                            <LinearProgress />
                        </DialogContent>
                    </>
                    :
                    <form onSubmit={formik.handleSubmit} >
                        <DialogContent dividers={true}>
                            <Stack alignContent={'center'} alignItems={'center'}>
                                <FormControl sx={{ margin: 1 }}>
                                    <InputLabel id="role-label">User(s)</InputLabel>
                                    <Select sx={{ display: 'flex', width: 300, fontSize: 13 }}
                                        labelId="assigned-users-label"
                                        id="assignedUsers"
                                        name="assignedUsers"
                                        label='User'
                                        multiple
                                        error={usersSelectedError}
                                        value={formik.values.assignedUsers}
                                        MenuProps={MenuProps}
                                        onChange={formik.handleChange}
                                        // renderValue={(selected) => selected.join(', ')}
                                        renderValue={(selected) => (
                                            <Typography
                                                noWrap={false}
                                                sx={{
                                                    display: 'block',
                                                    whiteSpace: 'normal',
                                                    wordWrap: 'break-word',
                                                    fontSize: 11
                                                }}
                                            >
                                                {selected.map(item => {
                                                    const user: { username: string } = JSON.parse(item);
                                                    return user.username;
                                                }).join(', ')}
                                            </Typography>
                                        )}
                                    >
                                        {getAllUsers.filter(excludeRoles).filter(excludeUsersThatAlreadyExistInGroup).map((user) => (
                                            <MenuItem key={user.username} value={JSON.stringify({ username: user.username, firstname: user.firstname, lastname: user.lastname })}>
                                                <Checkbox sx={{ height: 15, width: 15, marginRight: 1, paddingTop: 1 }} checked={formik.values.assignedUsers.some(item => JSON.parse(item).username === user.username)} />
                                                <ListItemText primary={<Typography variant="h5" style={{ fontSize: 11, marginTop: 1 }}>{user.isagency ? '(a) ' : <></>}{user.lastname}, {user.firstname} - {user.username}</Typography>} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Stack>
                        </DialogContent>
                    </form>
                }
                <DialogActions>
                    <Box display="flex" justifyContent="end" width="100%">
                        <Box display="flex" justifyContent="end" sx={{ alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}>
                            <Button variant='outlined' onClick={handleClose}>Cancel</Button>
                            <LoadingButton sx={{ marginLeft: 1 }} variant='contained' onClick={formik.submitForm}>Add User(s)</LoadingButton>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
})