import { observer } from "mobx-react-lite";
import React, { useEffect } from 'react';
import { useStore } from "../../../stores/store";
import { TableContainer, styled, tableCellClasses, Paper, Table, TableHead, TableCell, TableRow, Stack, TableBody, Box, Typography, Checkbox, ToggleButton, SelectChangeEvent, InputLabel, Select, MenuItem, IconButton, Button, Collapse, FormControl, Container, LinearProgress, Alert, Snackbar, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Menu, Tooltip } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import SchedulePlannerCheckboxComponent from "./SchedulePlannerCheckboxComponent";
import UnitSetupMain from "../../setups/unitsetup/UnitSetupMain";
import { format } from 'date-fns'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CheckBox, ChevronLeft, ChevronRight, Groups } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import SchedulePlannerAddUsersToGroup from "./SchedulePlannerAddUsersToGroup";
import DownloadIcon from '@mui/icons-material/Download';
import SaveIcon from '@mui/icons-material/Save';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import SchedulePlannerAddNewGroupDialog from "./SchedulePlannerAddNewGroupDialog";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { SchedulePlannerSaveModel } from "../../../models/Schedule/Planner/SchedulePlannerSaveModel";
import { SchedulePlannerGroup } from "../../../models/Schedule/Planner/SchedulePlannerGroup";
import { SchedulePlannerUser } from "../../../models/Schedule/Planner/SchedulePlannerUser";
import { LoadingButton } from "@mui/lab";
import { red } from "@mui/material/colors";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DateRangeIcon from '@mui/icons-material/DateRange';

const style = (theme: any) => ({
    margin: 0,
    top: 'auto',
    left: 'auto',
    bottom: 20,
    right: 20,
    position: 'fixed',

});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // borderWidth: 2,
        // borderStyle: 'solid',
        // borderColor: theme.palette.primary.main
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,

    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#ebebeb ',
        //backgroundColor: theme.palette.action.hover,
    },

}));
// interface Group {
//     id: number;
//     name: string;
//     roleid: number;
//     rolename: string;
//     shiftid: number;
//     shiftdesc: string;
//     shifttime: string;
//     unitid: number;
//     unitname: string;
// }
// interface UserInGroup {
//     groupid: number; //groupid
//     username: string;
//     days: boolean[];
// }
interface MenuState {
    anchorEl: HTMLElement | null;
    userId: number | string | null;
}

export default observer(function ScheduePlanner() {
    const { userStore, scheduleStore, shiftStore } = useStore();
    const { getCompanyRoles, loadCompanyRoles } = userStore;
    const { loadUnitSetups, getUnitSetups, loadSchedulePlannerSetups, loadingDetails, getSchedulePlannerSetups,
        loadSchedulePlannerGroups, loadSchedulePlannerUsers, getSchedulePlannerGroupsAll,
        getSchedulePlannerUsers, putSchedulePlannerChanges, savingData, applySchedulePlannerToSchedule, putNewSchedulePlannerSetup } = scheduleStore;
    const { loadShiftSetupsForRole, getShiftSetupsForRole, } = shiftStore;
    //const [selectedRoles, setSelectedRoles] = React.useState(['']);
    const [selectedrolename, setSelectedRoleName] = React.useState('');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [showAddUsersToGroup, setShowAddUsersToGroup] = React.useState(false);
    const [showAddNewGroup, setShowAddNewGroup] = React.useState(false);
    const [selectedGroupID, setSelectedGroupID] = React.useState(-1);
    const [numbers, setNumbers] = React.useState(Array.from({ length: 42 }, (_, i) => i + 1));
    const [startPosition, setStartPosition] = React.useState(0);
    const [groups, setGroups] = React.useState<SchedulePlannerGroup[]>([]);
    const [usersInGroup, setUsersInGroup] = React.useState<SchedulePlannerUser[]>([]);
    const [selectedMonth, setSelectedMonth] = React.useState('');
    const [selectedPlanner, setSelectedPlanner] = React.useState('');
    const [openSuccessSnack, setOpenSuccessSnack] = React.useState(false);
    const [openAddNewPlanner, setOpenAddNewPlanner] = React.useState(false);
    const [newPlannerName, setNewPlannerName] = React.useState('');
    const [addNewPlannerError, setAddNewPlannerError] = React.useState(false);
    const [menuState, setMenuState] = React.useState<MenuState>({
        anchorEl: null,
        userId: null
    });
    const pattern4On2Off: boolean[] = [
        true, true, true, true,  // 4 true
        false, false,            // 2 false
        true, true, true, true,  // 4 true
        false, false,            // 2 false
        true, true, true, true,  // 4 true
        false, false,            // 2 false
        true, true, true, true,  // 4 true
        false, false,            // 2 false
        true, true, true, true,  // 4 true
        false, false,            // 2 false
        true,true,true,true,     // 4 true
        false,false,             //2 false
        true,true,true,true,    //4 true
        false,false             //2 false
    ];
    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];
    const days = Array.from({ length: 42 }, (_, i) => i + 1);
    let addedGroupFromDialog: SchedulePlannerGroup = { cono: '', plannerid: Number.parseInt(selectedPlanner), groupid: 0, groupname: '', roleid: -1, rolename: '', shiftid: -1, shiftdescr: '', unitid: -1, unitname: '' };

    type DayOfWeek = 'SUN' | 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT';

    const getDayOfWeek = (month: number, dayIndex: number): DayOfWeek => {
        const firstDayOfMonth = new Date(new Date().getFullYear(), month - 1, 1).getDay();
        const days: DayOfWeek[] = [
            'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'
        ];
        return days[(firstDayOfMonth + dayIndex) % 7];
    };

    const handleCloseUserMenu = () => {
        setMenuState({
            anchorEl: null,
            userId: null
        });
    }
    const handleClickOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement>, username: string) => {
        //setAnchorEl(event.currentTarget);
        if (event == null) { return; }
        setMenuState({
            anchorEl: event.currentTarget,
            userId: username
        });

    }
    const handleApplyPatternToUserClick = (username: string, groupid: number) => {
        console.log("apply a pattern for " + username + ' group ' + groupid);
        setUsersInGroup((prevUsers) =>
            prevUsers.map((user) =>
                user.groupid === groupid && user.username === username
                    ? { ...user, days: [...pattern4On2Off] }
                    : user
            )
        );
        handleCloseUserMenu();
    }
    const handleApplyPatternToGroupClick = (groupid:number) =>{
        console.log("apply a pattern for " + ' group ' + groupid);
        setUsersInGroup((prevUsers) =>
            prevUsers.map((user) =>
                user.groupid === groupid
                    ? { ...user, days: [...pattern4On2Off] }
                    : user
            )
        );
    }
    const handleSwapUserClick = (username: string, groupid: number) => {
        console.log("swap a user" + username + ' group ' + groupid);
    }
    const handleRemoveUserClick = (username: string, groupid: number) => {
        console.log("remove a user" + username + ' group ' + groupid);
        const updatedUsers = usersInGroup.filter(user => !(user.username === username && user.groupid === groupid));
        setUsersInGroup(updatedUsers);
        handleCloseUserMenu();
        return updatedUsers;
    }

    useEffect(() => {
        document.title = 'Schedule Planner';
        loadSchedulePlannerSetups();
    }, []);

    const onCloseAddNewPlanner = () => {
        setNewPlannerName('');
        setOpenAddNewPlanner(false);
    }
    const handleSaveAddNewPlanner = () => {
        if (newPlannerName == '' || newPlannerName.length < 3) {
            setAddNewPlannerError(true);
            return;
        }
        else {
            setAddNewPlannerError(false);
        }
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        setSelectedGroupID(-1);
        setSelectedPlanner('');
        setSelectedMonth('');
        let s: SchedulePlannerSaveModel = { cono: cono, plannerid: -1, plannername: newPlannerName, groups: [], users: [], month: -1 }
        putNewSchedulePlannerSetup(s).then(() => {
            setOpenAddNewPlanner(false);
            loadSchedulePlannerSetups();
            setNewPlannerName('');
        })
    }
    const onCreateNewClick = () => {
        setOpenAddNewPlanner(true);
    }

    const handleAddUsersToGroupOpen = (groupid: number, role: string) => {
        console.log('we got ' + role);
        setSelectedGroupID(groupid);
        setSelectedRoleName(role);
        setShowAddUsersToGroup(true);
    }

    const addGroup = (): void => {
        setShowAddNewGroup(true);
        // setGroups([...groups, { id: newId, name: `Group ${newId}` }]);
    };

    const removeGroup = (id: number): void => {
        const filteredGroups = groups.filter(group => group.groupid !== id);
        const reorderedGroups = filteredGroups.map((group, index) => ({
            ...group,
            id: index + 1,
            name: `Group ${index + 1}`
        }));
        setGroups(reorderedGroups);

        const filteredUsers = usersInGroup.filter(user => user.groupid !== id);
        setUsersInGroup(filteredUsers);
    };
    // const addUserToGroup = (groupid: number, username: string) => {
    //     const newuser = { groupid: groupid, username: username, days: pattern4On2Off };
    //     setUsersInGroup([...usersInGroup, newuser]);
    // }

    const handleSavePlannerClick = () => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let s: SchedulePlannerSaveModel = { cono: cono, plannerid: Number.parseInt(selectedPlanner), plannername: '', groups: groups, users: usersInGroup, month: 0 }
        putSchedulePlannerChanges(s).then(() => {
            setOpenSuccessSnack(true);
        });
    }

    const handleApplyShiftsToSchedule = () => {
        if (!window.localStorage.getItem('cono')) {
            return;
        }
        let cono = window.localStorage.getItem('cono');
        if (!cono) {
            return;
        }
        let s: SchedulePlannerSaveModel = { cono: cono, plannerid: Number.parseInt(selectedPlanner), plannername: '', groups: groups, users: usersInGroup, month: Number.parseInt(selectedMonth) }
        applySchedulePlannerToSchedule(s).then(() => {
            setOpenSuccessSnack(true);
        });
    }

    const handleCloseSnack = () => {
        setOpenSuccessSnack(false);
    }

    const handleCloseDialog = () => {
        setDialogOpen(false);
    }

    const handleOnCloseAddNewGroup = (g: SchedulePlannerGroup) => {
        console.log('try to add group ' + g.roleid);
        if (g.shiftid == undefined || g.shiftid < 0) {
            setShowAddNewGroup(false);
            return;
        }
        addedGroupFromDialog = g;

        const gs = groups;
        g.groupname = 'Group ' + gs.length;
        g.groupid = gs.length;
        setGroups(prevGroups => [...prevGroups, g]);
        setShowAddNewGroup(false);
    }

    const handleOnCloseAddUsers = (selected: string[]) => {
        setShowAddUsersToGroup(false);
        if (selected.length > 0) {
            setUsersInGroup(prevUsers => {
                const parsedUsers = selected.map(s => JSON.parse(s));
                const newUsers = parsedUsers.map(u => ({
                    cono: '',
                    plannerid: Number.parseInt(selectedPlanner),
                    groupid: selectedGroupID,
                    username: u.username,
                    firstname: u.firstname,
                    lastname: u.lastname,
                    days: pattern4On2Off
                }));
                return [...prevUsers, ...newUsers];
            });
        }
        console.log('there are ' + selected.length + ' and also ' + usersInGroup.length);
    }

    const shiftLeft = (groupid: number) => {
        usersInGroup.filter((user) => user.groupid == groupid).forEach((u) => {
            u.days = slideArrayLeft(u.days);
        })
        setStartPosition((prev) => prev - 1);
        console.log('try to shift all' + usersInGroup.filter((user) => user.groupid == groupid).length);
    };

    const shiftRight = (groupid: number) => {
        usersInGroup.filter((user) => user.groupid == groupid).forEach((u) => {
            u.days = slideArrayRight(u.days);
        })
        setStartPosition((prev) => prev + 1);
    };

    function slideArrayRight(arr: boolean[]): boolean[] {
        // Create a copy of the array to avoid modifying the original
        const result = [...arr];

        // Store the last element
        const lastElement = result[result.length - 1];

        // Shift all elements one position to the right
        for (let i = result.length - 1; i > 0; i--) {
            result[i] = result[i - 1];
        }

        // Place the last element at the start
        result[0] = lastElement;
        console.log('try to move left');
        return result;
    }
    function slideArrayLeft(arr: boolean[]): boolean[] {
        // Create a copy of the array to avoid modifying the original
        const result = [...arr];

        // Store the first element
        const firstElement = result[0];

        // Shift all elements one position to the left
        for (let i = 0; i < result.length - 1; i++) {
            result[i] = result[i + 1];
        }

        // Place the first element at the end
        result[result.length - 1] = firstElement;

        return result;
    }

    const shiftLeftIndividual = (u: SchedulePlannerUser) => {
        u.days = slideArrayLeft(u.days);
        setStartPosition((prev) => prev - 1);
    };

    const shiftRightIndividual = (u: SchedulePlannerUser) => {
        u.days = slideArrayRight(u.days);
        setStartPosition((prev) => prev + 1);
    };

    const handleCheckboxChanged = (event: React.ChangeEvent<HTMLInputElement>, u: SchedulePlannerUser, i: number) => {
        console.log('try to set ' + u.username + ' ' + i);
        if (u.days[i]) {
            u.days[i] = false;
        }
        else {
            u.days[i] = true;
        }
        setStartPosition((prev) => prev + 1);
    }

    // const handleRoleChange = (index: number, event: SelectChangeEvent<string>) => {
    //     let role = JSON.parse(event.target.value.toString());
    //     const newSelectedRoles = [...selectedRoles];
    //     newSelectedRoles[index] = role.role;
    //     setSelectedRoles(newSelectedRoles);
    //     //loadShiftSetupsForRole(Number.parseInt(role.roleid));
    // };

    // const shouldShowX = (index: number) => {
    //     const adjustedPosition = (index - startPosition) % 6;
    //     // Handle negative numbers correctly
    //     const normalizedPosition = adjustedPosition < 0 ? adjustedPosition + 6 : adjustedPosition;
    //     return normalizedPosition < 4;
    // };
    const handleChangeMonth = (event: SelectChangeEvent) => {
        setSelectedMonth(event.target.value);

        if (event.target.value == '0' || event.target.value == '2' || event.target.value == '4' ||
            event.target.value == '6' || event.target.value == '7' || event.target.value == '9' || event.target.value == '11') {
            setNumbers(Array.from({ length: 42 }, (_, i) => (i % 31) + 1));
        }
        if (event.target.value == '3' || event.target.value == '5' || event.target.value == '8' || event.target.value == '10') {
            setNumbers(Array.from({ length: 42 }, (_, i) => (i % 30) + 1));
        }
        if (event.target.value == '1') {
            const daysInFeb = isLeapYear(new Date().getFullYear()) ? 29 : 28;
            setNumbers(Array.from({ length: 42 }, (_, i) => (i % daysInFeb) + 1));
        }
    };
    function isLeapYear(year: number): boolean {
        // A year is a leap year if:
        // 1. It's divisible by 4 AND
        // 2. It's either:
        //    - Not divisible by 100 OR
        //    - Divisible by 400
        return (year % 4 === 0) && (year % 100 !== 0 || year % 400 === 0);
    }

    const [doneloading, setDoneLoading] = React.useState(false);
    const handleChangePlanner = (event: SelectChangeEvent<string>) => {
        setDoneLoading(false);
        setSelectedPlanner(event.target.value);
        setGroups([]);
        setUsersInGroup([]);
        console.log('planner is ' + selectedPlanner);
        async function fetchData() {
            await loadSchedulePlannerGroups(Number.parseInt(event.target.value));
            await loadSchedulePlannerUsers(Number.parseInt(event.target.value));
            setDoneLoading(true);
        }
        fetchData();
    }

    useEffect(() => {
        console.log("we got groups " + Array.from(getSchedulePlannerGroupsAll).length);
        setGroups([]);
        setUsersInGroup([]);

        getSchedulePlannerGroupsAll.map(g => {
            let newgroup: SchedulePlannerGroup = { cono: '', plannerid: Number.parseInt(selectedPlanner), groupid: g.groupid, groupname: g.groupname, roleid: g.roleid, rolename: g.rolename, shiftid: g.shiftid, shiftdescr: g.shiftdescr, unitid: g.unitid, unitname: g.unitname }
            setGroups(prevGroups => [...prevGroups, newgroup]);
            setShowAddNewGroup(false);
        })
        console.log("we got users " + Array.from(getSchedulePlannerUsers).length);
        if (getSchedulePlannerUsers != null && getSchedulePlannerUsers.length > 0) {
            getSchedulePlannerUsers.map((u) => {
                let newuser: SchedulePlannerUser = { cono: '', plannerid: Number.parseInt(selectedPlanner), groupid: u.groupid, username: u.username, days: u.days, firstname: u.firstname, lastname: u.lastname }
                setUsersInGroup(prevUsers => [...prevUsers, newuser]);
            })
        }
    }, [doneloading]);

    return (
        <Box>
            <Stack sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Planner:</InputLabel>
                        <Select
                            name="shiftid"
                            size='small'
                            sx={{ display: 'block', width: 200 }}
                            value={selectedPlanner}
                            onChange={handleChangePlanner}>
                            {getSchedulePlannerSetups.map((s, index) => (
                                <MenuItem key={index} value={String(index)}>
                                    {s.plannername}
                                </MenuItem>
                            ))}
                        </Select>
                    </Stack>
                    <LoadingButton
                        loading={savingData}
                        disabled={selectedPlanner == ''}
                        onClick={handleSavePlannerClick}
                        sx={{ marginLeft: 2, height: 40, alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}
                        variant="outlined"
                    >
                        <SaveIcon sx={{ marginRight: 1 }} />
                        Save
                    </LoadingButton>
                    <LoadingButton
                        loading={savingData}
                        onClick={onCreateNewClick}
                        sx={{ marginLeft: 2, height: 40, alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}
                        variant="outlined"
                    >
                        <AddCircleIcon sx={{ marginRight: 1 }} />
                        Create New
                    </LoadingButton>
                </Stack>
                <Stack direction={'row'} sx={{ alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                    <Stack>
                        <InputLabel id="demo-simple-select-autowidth-label" sx={{ marginTop: 1, textAlign: 'left' }}>Month:</InputLabel>
                        <Select
                            name="shiftid"
                            size='small'
                            sx={{ display: 'block', width: 200 }}
                            value={selectedMonth}
                            onChange={handleChangeMonth}>
                            {months.map((month, index) => {
                                const currentMonth = new Date().getMonth();
                                const isWithinTwoMonths = index >= currentMonth || (index > 10 && index <= currentMonth + 2);
                                return (
                                    <MenuItem
                                        key={index}
                                        value={String(index)}
                                        disabled={!isWithinTwoMonths}
                                    >
                                        {month}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </Stack>
                    <LoadingButton
                        loading={savingData}
                        onClick={handleApplyShiftsToSchedule}
                        disabled={selectedMonth == ''}
                        sx={{ marginLeft: 2, height: 40, alignContent: 'end', alignItems: 'end', alignSelf: 'end' }}
                        variant="contained"
                    >
                        <EventAvailableIcon sx={{ marginRight: 1 }} />
                        Apply Shifts For Month
                    </LoadingButton>
                </Stack>
            </Stack>
            {loadingDetails ?
                <>
                    <LinearProgress sx={{ marginTop: 2 }} />
                </>
                :
                Number.parseInt(selectedPlanner) > -1 ?
                    <>
                        {groups.map((group: SchedulePlannerGroup, index: number) =>
                            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow key={index}>
                                            <StyledTableCell>
                                                <Stack direction={'row'} sx={{ width: 400, alignContent: 'center', alignItems: 'center', justifyContent: 'start' }}>
                                                    <Stack>
                                                        <Stack direction={'row'}>
                                                            {/* <Typography sx={{ marginRight: 2, fontSize: '1rem' }}>{group.groupname}</Typography> */}
                                                            <Typography sx={{ marginRight: 2, fontSize: '1rem' }}>{group.rolename}</Typography>
                                                            <Typography sx={{ fontSize: '1rem' }}>{group.unitname}</Typography>
                                                        </Stack>
                                                        <Stack direction={'row'}>
                                                            {group.shiftdescr != '' &&
                                                                <Typography sx={{ marginRight: 1, fontSize: '.7rem' }}>{group.shiftdescr}</Typography>}
                                                            {/* <Typography sx={{ fontSize: '.7rem' }}>{group.}</Typography> */}
                                                        </Stack>
                                                    </Stack>
                                                    {/* <FormControl>
                                            <InputLabel id="rolelabel">Role</InputLabel>
                                            <Select
                                                labelId="role-label"
                                                id="role"
                                                name="roleid"
                                                sx={{ minWidth: 150 }}
                                                value={selectedRoles[index]}
                                                onChange={(e) => handleRoleChange(index, e)}>
                                                {getCompanyRoles.map((role) => (
                                                    <MenuItem key={role.roleid} value={JSON.stringify({ roleid: role.roleid, role: role.role })}>
                                                        {role.role}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl> 
                                        <FormControl>
                                            <InputLabel id="shiftlabel">Shift</InputLabel>
                                            <Select
                                                labelId="unit-label"
                                                id="unit"
                                                name="shiftid"
                                                sx={{ minWidth: 180, marginLeft: 1, marginRight: 1 }}>
                                                {getShiftSetupsForRole.map((shift) => (
                                                    <MenuItem key={shift.shiftid} value={shift.shiftid}>{format(new Date(shift.shiftstarttime), "hh:mm a")} - {format(new Date(shift.shiftendtime), "hh:mm a")} - {shift.descr}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <FormControl>
                                            <InputLabel id="unit-label">Unit</InputLabel>
                                            <Select
                                                labelId="unit-label"
                                                id="unit"
                                                name="unit"
                                                label='Unit'
                                                sx={{ minWidth: 150 }}>
                                                {getUnitSetups.map((unit, index) => (
                                                    <MenuItem key={index} value={String(index)}>
                                                        {unit.unitname}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>*/}
                                                    <Stack direction={'row'} sx={{ marginLeft: 5, alignContent: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Tooltip title="Add User(s) to Group">
                                                            <IconButton
                                                                onClick={() => handleAddUsersToGroupOpen(group.groupid, group.rolename)}
                                                                size="small"
                                                                sx={{ marginRight: 3, width: 15, height: 15, color: 'white' }}
                                                            >
                                                                <PersonAddIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Apply Shift Pattern To Group">
                                                            <IconButton
                                                                onClick={() => handleApplyPatternToGroupClick(group.groupid)}
                                                                sx={{ width: 15, height: 15, color: 'white' }}
                                                            >
                                                                <DateRangeIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Move Shifts To The Left">
                                                            <IconButton
                                                                onClick={() => shiftLeft(group.groupid)}
                                                                size="small"
                                                                sx={{ marginLeft: 4, marginRight: .5, width: 15, height: 15, color: 'white' }}
                                                            >
                                                                <ChevronLeft />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Move Shifts To The Right">
                                                            <IconButton
                                                                onClick={() => shiftRight(group.groupid)}
                                                                sx={{ marginLeft: .5, width: 15, height: 15, color: 'white' }}
                                                            >
                                                                <ChevronRight />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete Group">
                                                            <IconButton
                                                                onClick={() => removeGroup(group.groupid)}
                                                                size="small"
                                                                sx={{ marginLeft: 4, width: 15, height: 15 }}
                                                                color="error"
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </Stack>
                                            </StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {usersInGroup.filter(g => g.groupid === group.groupid).map(group => (
                                            <TableRow key={group.username + group.plannerid}>
                                                <TableCell>
                                                    <TableContainer>
                                                        <Table size='small'>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell sx={{ width: 170, margin: 0, padding: 0 }}></TableCell>
                                                                    <TableCell sx={{ margin: 0, padding: 0 }}></TableCell>
                                                                    <TableCell sx={{ margin: 0, padding: 0 }}></TableCell>
                                                                    {numbers.map((num, index) => (
                                                                        <TableCell sx={{ margin: 0, padding: 0 }}>
                                                                            <Typography fontSize={9} textAlign={'center'} sx={{ height: 11, padding: 0, marginBottom: .2, alignContent: 'center', 
                                                                                alignItems: 'center', alignSelf: 'center', justifyContent: 'center', justifySelf: 'center' 
                                                                                }}>{num}</Typography>
                                                                            {selectedMonth != '' &&
                                                                                <Typography fontSize={9} textAlign={'center'} sx={{ height: 11, padding: 0, marginBottom: .5, 
                                                                                alignContent: 'center', alignItems: 'center', alignSelf: 'center', 
                                                                                justifyContent: 'center', justifySelf: 'center', fontWeight: getDayOfWeek(Number.parseInt(selectedMonth) + 1, index) === 'SUN' ? 'bold' : 'normal' }}>{getDayOfWeek(Number.parseInt(selectedMonth) + 1, index)}</Typography>}
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell sx={{ width: 170, fontSize: 9, margin: 0, padding: 0 }}>
                                                                        <Stack>
                                                                            <Typography fontSize={9}>{group.lastname}, {group.firstname}</Typography>
                                                                            <Typography fontSize={9}>({group.username})</Typography>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    <TableCell sx={{ margin: 0, padding: 0 }}>
                                                                        <IconButton
                                                                            size="small"
                                                                            onClick={(e) => handleClickOpenUserMenu(e, group.username)}>
                                                                            <MoreVertIcon fontSize="small" />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="user-menu"
                                                                            anchorEl={menuState.anchorEl}
                                                                            open={menuState.userId === group.username}
                                                                            onClose={handleCloseUserMenu}
                                                                            anchorOrigin={{
                                                                                vertical: 'center',
                                                                                horizontal: 'right',
                                                                            }}
                                                                            transformOrigin={{
                                                                                vertical: 'center',
                                                                                horizontal: 'left',
                                                                            }}
                                                                        >
                                                                            <MenuItem sx={{ fontSize: 12 }} onClick={() => handleApplyPatternToUserClick(group.username, group.groupid)}>
                                                                                Reset Pattern
                                                                            </MenuItem>
                                                                            {/* <MenuItem sx={{ fontSize: 12 }} onClick={() => handleSwapUserClick(group.username, group.groupid)}>
                                                                                Swap User
                                                                            </MenuItem> */}
                                                                            <MenuItem
                                                                                onClick={() => handleRemoveUserClick(group.username, group.groupid)}
                                                                                sx={{ color: red[600], fontSize: 12 }}
                                                                            >
                                                                                Remove User
                                                                            </MenuItem>
                                                                        </Menu>
                                                                    </TableCell>
                                                                    <TableCell sx={{ margin: 0, padding: 0 }}>
                                                                        <Stack direction={'row'}>
                                                                            <IconButton
                                                                                onClick={() => shiftLeftIndividual(group)}
                                                                                size="small"
                                                                                sx={{ width: 10, height: 10 }}>
                                                                                <ChevronLeft />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                onClick={() => shiftRightIndividual(group)}
                                                                                size="small"
                                                                                sx={{ marginLeft: .5, width: 10, height: 10 }}>
                                                                                <ChevronRight />
                                                                            </IconButton>
                                                                        </Stack>
                                                                    </TableCell>
                                                                    {group.days.map((num: boolean, index: number) => (
                                                                        index < numbers.length &&
                                                                        <TableCell sx={{ margin: 0, padding: 0, textAlign: 'center' }} >
                                                                            <Box alignSelf={'center'} alignContent={'center'}>
                                                                                <Checkbox size="small" style={{ padding: .5, margin: 1, fontSize: 10, transform: 'scale(.8)' }} checked={num} onChange={(e) => handleCheckboxChanged(e, group, index)} />
                                                                            </Box>
                                                                        </TableCell>
                                                                    ))}
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}

                        {selectedPlanner != '' &&
                            <Button
                                onClick={addGroup}
                                sx={{ marginTop: 2 }}
                                className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
                            >
                                <AddCircleIcon />
                                Add New Group
                            </Button>}            </> : <></>}
            <SchedulePlannerCheckboxComponent open={dialogOpen}
                onClose={handleCloseDialog} />
            <SchedulePlannerAddUsersToGroup open={showAddUsersToGroup}
                alreadyaddedusers={usersInGroup} groupid={selectedGroupID}
                onClose={handleOnCloseAddUsers}
                rolename={selectedrolename} />

            <SchedulePlannerAddNewGroupDialog
                addedGroup={addedGroupFromDialog}
                open={showAddNewGroup} onClose={handleOnCloseAddNewGroup} />
            <Snackbar open={openSuccessSnack} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} sx={{ width: "100%" }} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" variant="filled" sx={{ fontSize: 24 }}>
                    Successfully Saved Changes
                </Alert>
            </Snackbar>
            <Dialog open={openAddNewPlanner} onClose={onCloseAddNewPlanner}>
                <DialogTitle>Create New Planner</DialogTitle>
                <DialogContent dividers={true}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="plannerName"
                        label="Planner Name"
                        type="text"
                        fullWidth
                        value={newPlannerName}
                        required

                        onChange={(e) => setNewPlannerName(e.target.value)}
                        error={addNewPlannerError}
                        helperText={addNewPlannerError ? "Planner Name is required" : ""}
                        inputProps={{ maxLength: 100 }}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={savingData} variant="outlined" onClick={onCloseAddNewPlanner}>Cancel</LoadingButton>
                    <LoadingButton loading={savingData} variant="contained" onClick={handleSaveAddNewPlanner}>Create Planner</LoadingButton>
                </DialogActions>
            </Dialog>
        </Box>
    )
}) 